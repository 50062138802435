import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import RedirectButton from '../components/RedirectButton';
import DeleteButton from '../components/DeleteButton';

export default function Reservation() {
    const [waitingReservations, setWaitingReservations] = useState([]);
    const [reservationsTrue, setReservationsTrue] = useState([]);
    const [error, setError] = useState(null);
    const [modalContent, setModalContent] = useState('');
    const navigate = useNavigate();

    // Nouvel état pour gérer le rafraîchissement des données
    const [refreshData, setRefreshData] = useState(false);

    const fetchReservations = async () => {
        try {
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
            const user_id = decodedToken.userId;

            const response = await axios.get('https://drimo.alwaysdata.net/api/reservation', {
                params: { user_id }
            });

            setWaitingReservations(response.data.waitingReservation);
            setReservationsTrue(response.data.reservationsTrue);
        } catch (error) {
            console.error('Error fetching reservations:', error);
        }
    };

    // Utilisation du `useEffect` pour appeler `fetchReservations` lorsqu'un changement est détecté dans `refreshData`
    useEffect(() => {
        fetchReservations();
    }, [refreshData]); // Ajout de `refreshData` comme dépendance

    const formatDateTime = (dateTimeObj) => {
        const { day, hour, minute, date } = dateTimeObj;
        return `${day} ${date}, à ${hour}:${minute}`;
    };

    const handleAcceptReservation = async (id, email, fName, dateTime, brand_id) => {
        try {
            const response = await axios.put(`https://drimo.alwaysdata.net/api/reservation/${id}/accept`, {
                email,
                fName,
                dateTime,
                brand_id
            });
            console.log('Reservation status updated:', response.data);

            // Changer la valeur de `refreshData` pour déclencher un rafraîchissement des données
            setRefreshData(prev => !prev);
        } catch (error) {
            console.error('Error updating reservation status:', error);
        }
    };

    const handleDelete = async (id, email, fName, dateTime, prestation) => {
        try {
            await axios.delete(`https://drimo.alwaysdata.net/api/reservation/${id}`, {
                data: { email, fName, dateTime, prestation }
            });
            console.log('Reservation deleted');

            // Changer la valeur de `refreshData` pour déclencher un rafraîchissement des données
            setRefreshData(prev => !prev);
        } catch (error) {
            console.error('Error deleting reservation:', error);
            setError('Échec de la suppression de la réservation.');
        }
    };

    const openModal = (content) => {
        setModalContent(content);
    };

    const closeModal = () => {
        setModalContent('');
    };

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6">
                    <h3 className="pt-2 pb-2 text-dark has-text-weight-bold has-text-centered is-size-4">Réservations en attente :</h3>
                    <div className="is-flex is-justify-content-end mb-6">
                        <RedirectButton to="/reservation/create" label="Création d'une réservation" className="is-success has-text-link" />
                    </div>
                    {waitingReservations.length === 0 ? (
                        <div className="notification is-info has-text-centered has-text-link">
                            Vous n'avez pas de réservation en attente pour le moment.
                        </div>
                    ) : (
                        <ul>
                            {waitingReservations.map((reservation, index) => (
                                <li key={index} className="mb-4 has-background-info rounded-corners">
                                    <div className="columns">
                                        <div className="column is-1 ml-6">
                                            <p className="has-text-link has-text-weight-semibold">
                                                <span className="icon is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Nom :</strong> {reservation.customerLName}</p>
                                                        <p><strong>Prénom :</strong> {reservation.customerFName}</p>
                                                        <p><strong>Email :</strong> {reservation.customerEmail}</p>
                                                        <p><strong>Téléphone :</strong> {reservation.customerPhone}</p>
                                                    </>
                                                )}>
                                                    <p>Client</p>
                                                    <i className="fas fa-user ml-1 has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-3 has-text-link has-text-weight-semibold">
                                            <p className="is-clickable" onClick={() => openModal(
                                                <p><strong>{formatDateTime(reservation.dateTime[0])}</strong></p>
                                            )}>
                                                {reservation.dateTime[0].date}
                                                <span className="icon ml-1">
                                                    <i className="fa-regular fa-calendar-days has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-3 has-text-link has-text-weight-semibold">
                                            <p className="is-clickable" onClick={() => openModal(
                                                <>
                                                    <p><strong>Prestation :</strong> {reservation.prestation[0]?.name}</p>
                                                    <p><strong>Prix :</strong> {reservation.prestation[0]?.price}€</p>
                                                    <p><strong>Commentaire :</strong> {reservation.comment}</p>
                                                    <p><strong>Enseigne :</strong> {reservation.brand_name}</p>
                                                </>
                                            )}>
                                                Détails
                                                <span className="icon">
                                                    <i className="fa-solid fa-circle-info has-text-success ml-1"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-1">
                                            {reservation.payment_status === true ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement a été accepté</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-check" style={{ color: "#1338BE" }}></i>
                                                    </span>
                                                </p>
                                            ) : reservation.payment_status === null ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement est en attente</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-pause" style={{ color: "#FEE135" }}></i>
                                                    </span>
                                                </p>
                                            ) : reservation.payment_status === false ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement a été refusé</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-xmark" style={{ color: "#BF0A30" }}></i>
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="has-text-link">Erreur</p>
                                            )}
                                        </div>
                                        <div className="column">
                                            <button onClick={() => handleAcceptReservation(reservation._id, reservation.customerEmail, reservation.customerFName, reservation.dateTime, reservation.brand_id)} className="button has-text-success has-background-link m-1">
                                                <i className="fa-solid fa-check"></i>
                                            </button>
                                            <RedirectButton to={`/reservation/refuse/${reservation._id}`} label={<i className="fa-solid fa-xmark"></i>} className="has-text-link has-background-success m-1" />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}

                    <div className="horizontal-bar my-6 has-text-success"></div>

                    <h3 className="pt-2 pb-6 text-dark has-text-weight-bold has-text-centered is-size-4">Prochaines réservations :</h3>
                    {reservationsTrue.length === 0 ? (
                        <div className="notification is-info has-text-centered has-text-link">
                            Vous n'avez pas de réservation de prévu pour le moment.
                        </div>
                    ) : (
                        <ul>
                            {reservationsTrue.map((reservation, index) => (
                                <li key={index} className="mb-4 has-background-info rounded-corners">
                                    <div className="columns">
                                        <div className="column is-1 ml-6">
                                            <p className="has-text-link has-text-weight-semibold">
                                                <span className="icon is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Nom :</strong> {reservation.customerLName}</p>
                                                        <p><strong>Prénom :</strong> {reservation.customerFName}</p>
                                                        <p><strong>Email :</strong> {reservation.customerEmail}</p>
                                                        <p><strong>Téléphone :</strong> {reservation.customerPhone}</p>
                                                    </>
                                                )}>
                                                    <p>Client</p>
                                                    <i className="fas fa-user ml-1 has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-3 has-text-link has-text-weight-semibold">
                                            <p className="is-clickable" onClick={() => openModal(
                                                <p><strong>{formatDateTime(reservation.dateTime[0])}</strong></p>
                                            )}>
                                                {reservation.dateTime[0].date}
                                                <span className="icon ml-1">
                                                    <i className="fa-regular fa-calendar-days has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-3 has-text-link has-text-weight-semibold">
                                            <p className="is-clickable" onClick={() => openModal(
                                                <>
                                                    <p><strong>Prestation :</strong> {reservation.prestation[0].name}</p>
                                                    <p><strong>Prix :</strong> {reservation.prestation[0].price}€</p>
                                                    <p><strong>Commentaire :</strong> {reservation.comment}</p>
                                                    <p><strong>Enseigne :</strong> {reservation.brand_name}</p>
                                                </>
                                            )}>
                                                Détails
                                                <span className="icon">
                                                    <i className="fa-solid fa-circle-info has-text-success ml-1"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-1">
                                            {reservation.payment_status === true ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement a été accepté</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-check" style={{ color: "#1338BE" }}></i>
                                                    </span>
                                                </p>
                                            ) : reservation.payment_status === null ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement est en attente</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-pause" style={{ color: "#FEE135" }}></i>
                                                    </span>
                                                </p>
                                            ) : reservation.payment_status === false ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement a été refusé</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-xmark" style={{ color: "#BF0A30" }}></i>
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="has-text-link">Erreur</p>
                                            )}
                                        </div>
                                        <div className="column">
                                            <RedirectButton to={`/reservation/update/${reservation._id}`} label={<i className="fa-solid fa-pen"></i>} className="has-text-success has-background-link m-1" />
                                            <DeleteButton id={reservation._id} onDelete={() => handleDelete(reservation._id, reservation.customerEmail, reservation.customerFName, reservation.dateTime, reservation.prestation)} label={<i className="fa-solid fa-trash"></i>} className="has-text-link has-background-success my-1 ml-1" />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}

                    {modalContent && (
                        <div className="modal is-active">
                            <div className="modal-background" onClick={closeModal}></div>
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title">Informations</p>
                                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                                </header>
                                <section className="modal-card-body">
                                    {modalContent}
                                </section>
                                <footer className="modal-card-foot">
                                    <button className="button is-primary has-text-link" onClick={closeModal}>Fermer</button>
                                </footer>
                            </div>
                        </div>
                    )}
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}