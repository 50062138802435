import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function DeleteButton({ id, onDelete, label, className, redirectPath }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); // Hook pour naviguer

  const handleDelete = async () => {
    console.log(`Attempting to delete item with id: ${id}`);
    await onDelete(id);
    setIsModalOpen(false);
    if (redirectPath) {
      navigate(redirectPath); // Redirection vers la page spécifiée après suppression
    }
  };

  const openModal = () => {
    console.log("Opening modal for deletion confirmation");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("Closing modal without deleting");
    setIsModalOpen(false);
  };

  return (
    <>
      <button className={`button ${className}`} onClick={openModal}>
        {label}
      </button>

      {isModalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirmer la suppression</p>
              <button className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            <section className="modal-card-body">
              <p>Êtes-vous sûr de vouloir supprimer ? Cette action est irréversible.</p>
            </section>
            <footer className="modal-card-foot level">
              <div className="level-left">
                <div className="level-item">
                  <button className="button is-danger has-text-link m-1" onClick={handleDelete}>
                    Supprimer
                  </button>
                </div>
                <div className="level-item">
                  <button className="button has-text-link m-1" onClick={closeModal}>
                    Annuler
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      )}
    </>
  );
}