import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import RedirectButton from '../components/RedirectButton';
import DeleteButton from '../components/DeleteButton';
import { jwtDecode } from 'jwt-decode';

export default function Brands() {
  const [brands, setBrands] = useState([]);
  const [subType, setSubType] = useState("");
  const [resultMessage, setResultMessage] = useState(null);
  const [inventories, setInventories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem('token');
      let decodedToken;
      if (token) {
        try {
          decodedToken = jwtDecode(token);
          setSubType(decodedToken.stripe.sub_type);
        } catch (error) {
          decodedToken = null;
        }
      }

      try {
        const response = await axios.get(`https://drimo.alwaysdata.net/api/brands?user_id=${decodedToken.userId}`);
        setBrands(response.data);
        console.log('Response Data:', response.data);

        // Fetch inventories if subType is "Indépendant"
        if (decodedToken && subType === "Indépendant" || subType === "Premium") {
          const userId = decodedToken.userId;
          const inventoryResponse = await axios.get(`https://drimo.alwaysdata.net/api/inventories`, {
            params: { user_id: userId },
          });
          console.log('Fetched inventories:', inventoryResponse.data);
          setInventories(inventoryResponse.data);
        }
      } catch (error) {
        console.error('Error fetching brands or inventories:', error);
      }
    }
    fetchData();
  }, [subType]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://drimo.alwaysdata.net/api/brands/${id}`);
      setResultMessage({ type: 'success', text: 'Enseigne supprimée avec succès.' });
      setBrands(brands.filter(brand => brand._id !== id));
    } catch (error) {
      setResultMessage({ type: 'error', text: 'Échec de la suppression de l\'enseigne.' });
      console.error('Error deleting brand:', error);
    }
  };

  const handleInventoryDelete = async (id) => {
    try {
      const response = await axios.delete(`https://drimo.alwaysdata.net/api/inventories/${id}`);
      console.log('Inventory deleted:', response.data);
      setInventories(prev => prev.filter(inventory => inventory._id !== id));
    } catch (error) {
      console.error('Error deleting inventory:', error);
      setResultMessage({ type: 'error', text: 'Échec de la suppression du produit.' });
    }
  };

  const openModal = (text) => {
    setModalText(text);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalLink = (brandId) => {
    const handleLinkClick = () => {
      const link = `https://www.clahess.com/brand/${brandId}`;
      navigator.clipboard.writeText(link)
        .then(() => {
          alert('Le lien a été copié dans le presse-papier !');
        })
        .catch((err) => {
          console.error('Erreur lors de la copie du lien :', err);
          alert('Échec de la copie du lien.');
        });
    };

    const clickableLink = (
      <div>
        <p>Voici le lien que vous pouvez partager à vos clients pour qu'ils aient accès à votre espace. Ce lien leurs permettra de prendre des réservations chez votre enseigne.</p>
        <p>Lien : <span onClick={handleLinkClick} className="has-text-weight-semibold is-underlined is-clickable" style={{ cursor: 'pointer' }}>https://www.clahess.com/brand/{brandId}</span></p>
      </div>
    );
    setModalText(clickableLink);
    setIsModalOpen(true);
  };

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
      <div className="container is-fluid">
        <Header />

        {resultMessage && (
          <div className={`notification ${resultMessage.type === 'success' ? 'is-success has-text-link' : 'is-danger has-text-link'}`}>
            {resultMessage.text}
          </div>
        )}
      </div>
      <div className="columns pt-2">
        <div className="column is-3">
          <div className="has-background-info py-2 pr-2 pl-4 navBorder">
            <NavbarLog />
          </div>
        </div>
        <div className="column is-9 mt-2">
          <h3 className="pt-2 pb-3 text-dark has-text-weight-bold has-text-centered is-size-4">
            {subType === "Entreprise" ? ("Mes enseignes :") : ("Mon enseigne :")}
          </h3>
          {!(subType === "Indépendant" && brands.length === 1) && !(subType === "Premium" && brands.length === 1) && (
            <div className="is-flex is-justify-content-end mb-4">
              <RedirectButton to="/brands/create" label="Ajouter une enseigne" className="is-success has-text-link m-1" />
            </div>
          )}
          {brands.length > 0 ? (
            <ul>
              {brands.map(brand => (
                <li key={brand._id} className="m-1">
                  <div className="columns has-background-info rounded-corners m-2">
                    <div className="column is-3 my-3">
                      <h2 className="has-text-link has-text-weight-semibold ml-3 is-size-5">{brand.name}</h2>
                    </div>
                    <div className="column is-6 my-3">
                      <p className="has-text-link has-text-weight-semibold is-size-5">{`${brand.address.street}, ${brand.address.city} ${brand.address.zip}`}</p>
                    </div>
                    <div className="column is-1 my-3">
                      <span className="icon is-clickable" onClick={() => openModalLink(brand._id)}>
                        <i className="fa-solid fa-up-right-from-square has-text-success"></i>
                      </span>
                    </div>
                    <div className="column is-pulled-right m-1">
                      <RedirectButton to={`/brands/update/${brand._id}`} label={<i className="fa-solid fa-pen"></i>} className="has-background-link has-text-success m-1" />
                      <DeleteButton id={brand._id} onDelete={handleDelete} label={<i className="fa-solid fa-trash"></i>} className="has-background-success has-text-link my-1 ml-1" redirectPath="/brands" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="p-1 has-background-info has-text-link rounded-corners">
              <li>Aucune enseigne trouvé.</li>
            </ul>
          )}
          {(subType === "Entreprise") ? (
            // Afficher quelque chose pour "Entreprise"
            <>
              {/* Contenu pour Entreprise */}
            </>
          ) : (subType === "Indépendant" || subType === "Premium") ? (
            <>
              <div className="horizontal-bar my-6 has-text-success"></div>

              <h3 className="pt-2 pb-3 text-dark has-text-weight-bold has-text-centered is-size-4">
                Mes Produits :
              </h3>
              <div className="container is-fluid is-flex is-justify-content-end mb-4">
                <RedirectButton to="/inventory/create" label="Ajouter un produit" className="is-success has-text-link m-1" />
              </div>
              {inventories.length > 0 ? (
                <ul>
                  {inventories.map((inventory) => (
                    <li key={inventory._id} className="m-1">
                      <div className="columns has-background-info rounded-corners m-2">
                        <div className="column is-4 my-3">
                          <h2 className="has-text-link has-text-weight-semibold ml-3 is-size-5">
                            {inventory.name}
                            <span
                              className="icon ml-1 is-clickable"
                              onClick={() => openModal(`Indique le nom du produit.`)}>
                              <i className="fa-solid fa-circle-info has-text-success"></i>
                            </span>
                          </h2>
                        </div>
                        <div className="column is-2 my-3">
                          <p className="has-text-link has-text-weight-semibold is-size-5">
                            {inventory.quantity}
                            <span
                              className="icon ml-1 is-clickable"
                              onClick={() => openModal(`Indique la quantité correspond au produit.`)}>
                              <i className="fa-solid fa-circle-info has-text-success"></i>
                            </span>
                          </p>
                        </div>
                        <div className="column is-2 my-3">
                          <p className="has-text-link has-text-weight-semibold is-size-5">
                            <span className="icon">
                              <i className={`has-text-success ${inventory.reminder ? "fa-solid fa-check" : "fa-solid fa-xmark"}`}></i>
                            </span>
                            <span
                              className="icon is-clickable"
                              onClick={() => openModal(`Le rappel de stock minimum est ${inventory.reminder ? `activé pour ce produit. Votre rappel s'effectura quand le stock attendra ${inventory.minimalQuantity}.` : "désactivé pour ce produit."}`)}>
                              <i className="fa-solid fa-circle-info has-text-success"></i>
                            </span>
                          </p>
                        </div>
                        <div className="column is-2 my-3">
                          <p className="has-text-link has-text-weight-semibold is-size-5">
                            <span className="icon">
                              <i className={`has-text-success ${inventory.sell ? "fa-solid fa-check" : "fa-solid fa-xmark"}`}></i>
                            </span>
                            <span
                              className="icon is-clickable"
                              onClick={() => openModal(`Ce produit ${inventory.sell ? `en vente. Le prix de ce produit est de ${inventory.price} €.` : "n'est pas en vente."}`)}>
                              <i className="fa-solid fa-circle-info has-text-success"></i>
                            </span>
                          </p>
                        </div>
                        <div className="column is-2 is-pulled-right m-1">
                          <RedirectButton to={`/inventory/update/${inventory._id}`} label={<i className="fa-solid fa-pen"></i>} className="has-background-link has-text-success m-1" />
                          <DeleteButton id={inventory._id} onDelete={() => handleInventoryDelete(inventory._id)} label={<i className="fa-solid fa-trash"></i>} className="has-background-success has-text-link my-1 ml-1" redirectPath="/inventory" />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="p-1 has-background-info has-text-link rounded-corners">
                  <li>Aucun produit trouvé.</li>
                </ul>
              )}
            </>
          ) : (
            // Sinon, pour les autres cas
            <p className="p-1 has-text-link has-background-info rounded-corners">
              Aucune enseigne ou produit disponible pour ce type d'utilisateur.
            </p>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Informations</p>
              <button className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            <section className="modal-card-body">
              <p>{modalText}</p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-primary has-text-link" onClick={closeModal}>Fermer</button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
}