import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Assurez-vous d'importer jwtDecode pour décoder le token
import 'bulma/css/bulma.min.css';
import "../style/index.css"; // Assurez-vous de créer ce fichier CSS pour les styles personnalisés

// Fonction pour formater une date en DD/MM/YYYY
function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default function Calendar() {
    const [coloredCalendar, setColoredCalendar] = useState({});

    useEffect(() => {
        // Décoder le token pour obtenir l'user_id
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        const user_id = decodedToken.userId;

        // Fetch the colored calendar data when the component mounts
        const fetchCalendar = async () => {
            try {
                const response = await axios.get('https://drimo.alwaysdata.net/api/calendar', {
                    params: { user_id } // Passez l'user_id en tant que paramètre
                });
                setColoredCalendar(response.data); // Utilisez directement les données colorisées du backend
            } catch (error) {
                console.error('Error fetching calendar data:', error);
            }
        };

        fetchCalendar();
    }, []);

    const generateNext30DaysCalendar = () => {
        const next30DaysCalendar = [];
        const now = new Date();

        for (let i = 0; i < 30; i++) {
            const date = new Date(now);
            date.setDate(now.getDate() + i);
            next30DaysCalendar.push(date);
        }

        return next30DaysCalendar;
    };

    const next30DaysCalendar = generateNext30DaysCalendar();
    const today = new Date().toISOString().split('T')[0];

    const daysOfWeek = ['Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.'];

    // Generate weeks with days aligned correctly
    const weeks = [];
    let week = Array(7).fill(null);
    next30DaysCalendar.forEach((dateObj) => {
        const dayOfWeek = dateObj.getDay() === 0 ? 6 : dateObj.getDay() - 1; // Convert Sunday from 0 to 6
        week[dayOfWeek] = dateObj;
        if (dayOfWeek === 6) {
            weeks.push(week);
            week = Array(7).fill(null);
        }
    });
    if (week.some((day) => day !== null)) {
        weeks.push(week);
    }

    return (
        <div className="container calendar-container">
            <div className="columns is-multiline is-centered">
                {daysOfWeek.map((day) => (
                    <div key={day} className="column is-one-seventh">
                        <div className="box day-box">
                            <p className="day-text">{day}</p>
                        </div>
                    </div>
                ))}
                {weeks.map((week, weekIndex) => (
                    <React.Fragment key={weekIndex}>
                        {week.map((dateObj, dayIndex) => {
                            const date = dateObj ? dateObj.toISOString().split('T')[0] : null;
                            const formattedDate = date ? formatDateToDDMMYYYY(dateObj) : '';
                            return (
                                <div key={dayIndex} className="column is-one-seventh">
                                    <div className={`box date-box ${formattedDate ? (coloredCalendar[formattedDate]?.color || '') : ''} ${date === today ? 'blue-border' : ''}`}>
                                        <p className="date-text">{dateObj ? dateObj.getDate() : ''}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}