import React, { useState, useEffect } from "react";
import { useStripeConnect } from "../hooks/useStripeConnect";
import {
  ConnectPayouts,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import Header from '../components/Header';
import NavbarLog from "../components/NavbarLog";
import {jwtDecode} from 'jwt-decode';

export default function Payouts() {
  const [connectedAccountId, setConnectedAccountId] = useState(jwtDecode(localStorage.getItem('token')).stripe.acc_id);
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  console.log(connectedAccountId);
  useEffect(() => {
    if (!connectedAccountId) {
      // Handle the case where the connected account ID is not present
      console.log('No connected account ID found in localStorage.');
    }
  }, [connectedAccountId]);

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
      <div className="container is-fluid">
        <Header />
        <h3 className="pt-2 pb-2 text-dark has-text-weight-bold has-text-centered is-size-4 mb-2">Mon Argent :</h3>
      <div className="container is-max-desktop mb-5">
      <div className="notification has-background-danger has-text-white">
            <strong>Veuillez noter que vous pourrez consulter la mise à jour quotidienne de vos portefeuilles, effectuée chaque jour aux alentours de minuit. Cela vous permettra de suivre en temps réel les montants disponibles, qui pourront prochainement être virés vers votre compte bancaire.

Nous vous invitons à vérifier régulièrement cette page pour rester informés de l'état de vos finances et anticiper les futurs virements.</strong>
          </div>
          </div>
      </div>
      <div className="columns">
        <div className="column is-3 mr-5">
          <div className="has-background-info py-2 pr-2 pl-4 navBorder">
            <NavbarLog />
          </div>
        </div>
        
        <div className="column is-7 ml-5">
      <div className="container mt-2">
      
        <div className="container">
          {connectedAccountId && stripeConnectInstance ? (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectPayouts />
            </ConnectComponentsProvider>
          ) : (
            <p>Chargement du paiement Strip...</p>
          )}
        </div>
      </div>
    </div>
    </div></div>
  );
}
