import Header from "../components/Header";
import NavbarLog from "../components/NavbarLog";

export default function SellHistory() {
    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns pt-2">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-9">
                    <div className="is-flex is-justify-content-center m-1 mt-1">
                        <img className="trente-cinq-pourcent" src="/travaux-en-cours.png" alt="Personne qui travaille" />
                    </div>
                    <div className="columns">
                        <div className="column is-2"></div>
                        <div className="column is-8 is-flex is-justify-content-center m-1">
                            <p className="has-text-weight-semibold has-text-success is-size-5 has-text-centered">Cette page est actuellement en cours de maintenance, nous reviendrons vers vous une fois cette page active.</p>
                        </div>
                        <div className="column is-2"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}