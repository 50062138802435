// src/components/SocketManager.js
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import { socket } from './socket';
const SOCKET_URL = 'https://drimo.alwaysdata.net'; // URL de votre serveur

const SocketManager = () => {
  const [socketv, setSocketv] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token')); // Obtenez le token JWT du localStorage
  const [isConnected, setIsConnected] = useState(socket.connected);
  if (typeof token === 'string' && token.trim() !== '') {

 console.log(jwtDecode(token));
  }
  useEffect(() => {
    if (typeof token === 'string' && token.trim() !== ''){
    setIsConnected(true);
    const newSocket = io(SOCKET_URL);

    // Extraire l'email du token JWT
    const decodedToken = jwtDecode(token);
    const email = decodedToken.email;
   
    // Joindre la room spécifique à l'email
    newSocket.emit('join', email);
    newSocket.on('stripe-update', (data) => {
      console.log('Received Stripe update:', data);
      handleStripeUpdate(data);
    });
   
    // Fonction asynchrone pour gérer la mise à jour du token
async function handleStripeUpdate(data) {
  console.log('Received Stripe update:', data);

  // Mettre à jour les données de l'utilisateur (stripe) dans le token JWT
  try {
    const response = await axios.post('https://drimo.alwaysdata.net/api/refresh-token', { email, data });
    const newToken = response.data.token;
    setToken(newToken);
    localStorage.setItem('token', newToken);
    console.log('Token updated:', newToken);
  } catch (error) {
    console.error('Error updating token:', error);
  }
}

    setSocketv(newSocket);

    return () => newSocket.close();
  } else {
    console.warn('No valid token found.');
  }
  }, [token]);

  return null;
};

export default SocketManager;
