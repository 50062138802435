import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bulma/css/bulma.min.css';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fName: '',
    lName: '',
    email: '',
    password: '',
    sexe: '',
    phone: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard'); // Rediriger vers le tableau de bord si l'utilisateur a déjà une session
    }
  }, [navigate]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://drimo.alwaysdata.net/api/register', formData);
      console.log(response.data);
      if (response.data.status === 'success') {
        localStorage.setItem('token', response.data.token);
        setSuccess('Inscription réussie! Redirection vers la page de connexion...');
        setError('');
        setFormData({
          fName: '',
          lName: '',
          email: '',
          password: '',
          sexe: '',
          phone: ''
        });
        setTimeout(() => {
          navigate('/sub');
        }, 2000); // Redirect after 2 seconds
      } else {
        setError(response.data.message || 'Erreur lors de l\'inscription');
        setSuccess('');
      }
    } catch (error) {
      console.error('Erreur d\'inscription :', error);
      setError('Erreur lors de l\'inscription');
      setSuccess('');
    }
  };

  return (
    <div id="root" className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="container is-fluid ">
        <Header />
        {error && <div className="notification is-danger">{error}</div>}
        {success && <div className="notification is-success">{success}</div>}
        <div className="columns py-6">
          <div className="column is-3"></div>
          <div className="column is-6 has-background-info rounded-corners">
            <h2 className="title is-flex is-justify-content-center has-text-link pt-2">Inscription</h2>
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label has-text-link">Prénom</label>
                <div className="control">
                  <input
                    className="input has-background-link has-text-success"
                    type="text"
                    name="fName"
                    value={formData.fName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-link">Nom</label>
                <div className="control">
                  <input
                    className="input has-background-link has-text-success"
                    type="text"
                    name="lName"
                    value={formData.lName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-link">Email</label>
                <div className="control">
                  <input
                    className="input has-background-link has-text-success"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-link">Mot de passe</label>
                <div className="control">
                  <input
                    className="input has-background-link has-text-success"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-link">Sexe</label>
                <div className="control">
                  <div className="select is-success">
                    <select
                      className="has-background-link has-text-success"
                      name="sexe"
                      value={formData.sexe}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Sélectionnez le sexe</option>
                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                      <option value="non-binaire">Non-binaire</option>
                      <option value="non-communique">Ne souhaite pas communiquer</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label has-text-link">Téléphone</label>
                <div className="control">
                  <input
                    className="input has-background-link has-text-success"
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    pattern="[0-9]{10}" // Assumes a 10-digit phone number
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="control is-flex is-justify-content-center py-3">
                  <button className="button is-success has-text-link" type="submit">S'inscrire</button>
                </div>
              </div>
            </form>
          </div>
          <div className="column is-3"></div>
        </div>
      </div>
      <div className="pt-6"></div>
      <Footer />
    </div>
  );
}