import React, { useRef } from 'react';
import Header from '../components/Header';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "../style/index.css";
import AuthCalendly from '../components/AuthCalendly';
import Footer from '../components/Footer';

export default function Home() {
  
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -carouselRef.current.offsetWidth, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: carouselRef.current.offsetWidth, behavior: 'smooth' });
    }
  };
  return (
    <div id="root" className="bg-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="container is-fluid ">
        <Header />
        <div className="columns is-centered pt-6">
          <div className="column is-1"></div>
          <div className="card has-background-info m-3">
            <div className="columns">
              <div className="column is-two-fifths has-text-centered">
                <h1 className="title is-2 has-text-link pt-2 pb-6">Drimo :</h1>
                <img src="/logo_home.png" alt="Drimo Logo" style={{ width: '200px' }} />
              </div>
              <div className="column is-1"></div>
              <div className="column">
                <h2 className="title is-2 has-text-link has-text-centered pb-5 pt-2">Optimisez votre activité avec un outil conçu pour les pros</h2>
                <ul className="has-text-link is-size-4 pl-1">
                  <li><span className="icon-text"><span className="icon custom-icon"><i className="fas fa-check"></i></span>Vos salons, un seul point de vente</span></li>
                  <li><span className="icon-text"><span className="icon custom-icon"><i className="fas fa-check"></i></span>Gérez vos réservations</span></li>
                  <li><span className="icon-text"><span className="icon custom-icon"><i className="fas fa-check"></i></span>Facturation en un clique</span></li>
                  <li><span className="icon-text"><span className="icon custom-icon"><i className="fas fa-check"></i></span>Vendez vos produits</span></li>
                  <li><span className="icon-text"><span className="icon custom-icon"><i className="fas fa-check"></i></span>Gestion de stock</span></li>
                </ul>
              </div>
              <div className="column is-1"></div>
            </div>
            <div className="has-text-centered pb-4">
              <a href="mailto:support@drimo.wecyde.com" className="button blue is-rounded is-large">Contactez-nous</a>
            </div>
          </div>
          <div className="column is-1"></div>
        </div>

        <div className="pb-6"></div>
        
        <h1 className="is-size-3 text-dark pb-3">Des milliers de personnes nous font déjà confiance, pourquoi pas vous ?</h1>

      </div>

      <section className="hero is-info">
        <div className="hero-body">
          <div className="card-carousel" ref={carouselRef}>
            <div className="card height-450 m-3">
              <div className="card-content height-450 has-background-link">
                <div className="columns">
                  <div className="column is-6">
                    <div className="title is-1">
                      Erik DUPONT
                    </div>
                    <img className="pb-4" src="/five-star.png" alt="note 5 étoiles" />
                    <p className="text-dark is-size-3">
                      Drimo répond parfaitement à mon besoin ! Je recommande.
                    </p>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-5 has-text-centered">
                    <img className="soixante-cinq-pourcent hide-on-small-screen" src="/profil.png" alt="chien" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card height-450 m-3">
              <div className="card-content height-450 has-background-link">
                <div className="columns">
                  <div className="column is-6">
                    <div className="title is-1">
                      Jean MESSAGER
                    </div>
                    <img className="pb-4" src="/five-star.png" alt="note 5 étoiles" />
                    <p className="text-dark is-size-3">
                      Drimo est un outil indispensable pour la gestion de mon activité. Une très bonne expérience.
                    </p>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-5 has-text-centered">
                    <img className="soixante-cinq-pourcent hide-on-small-screen" src="/profil.png" alt="chien" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card height-450 m-3">
              <div className="card-content height-450 has-background-link">
                <div className="columns">
                  <div className="column is-6">
                    <div className="title is-1">
                      Gaudry Le EM
                    </div>
                    <img className="pb-4" src="/five-star.png" alt="note 5 étoiles" />
                    <p className="text-dark is-size-3">
                      La vente de produit est beaucoup plus simple avec Drimo, merci !
                    </p>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-5 has-text-centered">
                    <img className="soixante-cinq-pourcent hide-on-small-screen" src="/profil.png" alt="chien" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card height-450 p-0 m-3">
              <div className="card-content height-450 has-background-link">
                <div className="columns">
                  <div className="column is-6">
                    <div className="title is-1">
                      Claire BAZIN
                    </div>
                    <img className="pb-4" src="/five-star.png" alt="note 5 étoiles" />
                    <p className="text-dark is-size-3">
                      Drimo m'a permis de gagner un temps précieux dans la gestion de mes réservations.
                    </p>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-5 has-text-centered">
                    <img className="soixante-cinq-pourcent hide-on-small-screen" src="/profil.png" alt="chien" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-buttons">
            <button onClick={scrollLeft}>{"<"}</button>
            <button onClick={scrollRight}>{">"}</button>
          </div>
        </div>
      </section>

      <div className="pt-6"></div>

      <section className="hero">
        <div className="hero-body">
          <div className="container" id="calendrier">
            <h1 className="title has-text-centered">Réservez un créneau</h1>
            <AuthCalendly />
          </div>
        </div>
      </section>

      <div className="pt-6"></div>

      <Footer />

    </div>
  );
}