import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bulma/css/bulma.min.css';

export default function CheckoutStatus () {
  const [status, setStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [brandId, setBrandId] = useState('');
  const [reservationId, setReservationId] = useState('');
  const [accId, setAccId] = useState('');
  const [transferGrp, setTransferGrp] = useState('');
  const navigate = useNavigate();

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    const brandIdFromUrl = urlParams.get('brand_id');
    const reservationIdFromUrl = urlParams.get('reservation_id');
    const accIdFromUrl = urlParams.get('acc_id');
    const transferGrpFromUrl = urlParams.get('transfer_group');

    setBrandId(brandIdFromUrl);
    setReservationId(reservationIdFromUrl);
    setAccId(accIdFromUrl);
    setTransferGrp(transferGrpFromUrl);

    try {
      const response = await axios.post('https://drimo.alwaysdata.net/session_status',
        { sessionId }
      );
      const data = response.data;

      setStatus(data.status);
      setPaymentStatus(data.payment_status);
      setCustomerEmail(data.customer_email);
      setCustomerName(data.customer_name);

      if (data.status === 'complete' && data.payment_status === 'paid') {
        const updateResponse = await axios.put(
          `https://drimo.alwaysdata.net/api/reservations/${reservationIdFromUrl}`,
          {
            status: null,
            payment_status: true,
            acc_id: accIdFromUrl,
            transfer_group: transferGrpFromUrl,
            checkout_session: sessionId
          }
        );
      }
      else {
        const updateResponse = await axios.put(
          `https://drimo.alwaysdata.net/api/reservations/${reservationIdFromUrl}`,
          {
            status: null,
            payment_status: false,
            acc_id: accIdFromUrl,
            transfer_group: transferGrpFromUrl,
            checkout_session: sessionId
          }
        );
      }

    } catch (error) {
      console.error('Erreur lors de la récupération de l\'état de la session :', error);
    }
  }, []);

  const handleRetryPayment = () => {
    navigate(`/brand/${brandId}`);
  };

  const buttonClass = "button is-medium is-outlined";

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
      {status === 'complete' && paymentStatus === 'paid' ? (
        <>
          <p className="m-5 is-underlined title is-size-1 has-text-success has-text-centered">Paiement réussi !</p>
          <p className="subtitle has-text-success is-size-3 has-text-centered">
            Merci pour votre achat, {customerName}.
          </p>
          <p className="subtitle has-text-success has-text-centered">
            Vous allez recevoir des informations concernant votre réservation à cette email : {customerEmail}.
          </p>
          <p className="subtitle has-text-success has-text-centered">Vous pouvez quitter sans soucis. A très vite !</p>
        </>
      ) : status === 'complete' && paymentStatus !== 'paid' ? (
        <div className="has-background-danger">
          <h1 className="title has-text-link has-text-centered">Paiement incomplet</h1>
          <p className="subtitle has-text-link has-text-centered">
            Votre paiement n'a pas été effectué. Veuillez réessayer.
          </p>
          <div className="is-flex is-justify-content-center">
            <button className={`${buttonClass} has-text-link has-background-success has-text-centered`} onClick={handleRetryPayment}>
              Repasser au paiement
            </button>
          </div>
        </div>
      ) : status === 'open' ? (
        <div className="has-background-warning">
          <h1 className="title has-text-black has-text-centered">Paiement incomplet</h1>
          <p className="subtitle has-text-black has-text-centered">
            Il semble que votre paiement n'a pas été finalisé. Veuillez réessayer.
          </p>
          <div className="is-flex is-justify-content-center">
            <button className={`${buttonClass} has-text-link has-background-success has-text-centered`} onClick={handleRetryPayment}>
              Repasser au paiement
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h1 className="title has-text-success">Chargement...</h1>
        </div>
      )}
    </div>
  );
}
