import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import RedirectButton from '../components/RedirectButton';
import DeleteButton from '../components/DeleteButton';
import { jwtDecode } from 'jwt-decode'; // Fix the import if needed

export default function ItemsForSale() {
    const [itemsForSale, setItemsForSale] = useState([]);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    const [subType, setSubType] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        const fetchItemsForSale = async () => {
            const token = localStorage.getItem('token');
            let decodedToken;
            if (token) {
                try {
                    decodedToken = jwtDecode(token);
                    setSubType(decodedToken.stripe.sub_type);
                    setEmail(decodedToken.email)
                } catch (error) {
                    decodedToken = null;
                }
            }
            try {
                const userId = decodedToken.userId;
                const response = await axios.get(`https://drimo.alwaysdata.net/api/items-for-sale`, {
                    params: { userId: userId }
                });
                setItemsForSale(response.data);
            } catch (err) {
                console.error('Error fetching items for sale:', err);
                setError('Failed to fetch items for sale.');
            }
        };

        fetchItemsForSale();
    }, []);

    const openModal = (text) => {
        setModalText(text);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`https://drimo.alwaysdata.net/api/inventories/${id}`);
            setItemsForSale(prev => prev.filter(item => item._id !== id));
            setMessage({ type: 'success', text: 'Item deleted successfully' });
        } catch (error) {
            console.error('Error deleting item:', error);
            setError('Failed to delete item.');
        }
    };

    if (error) {
        return <div className="notification is-danger">{error}</div>;
    }
    
    const manage_sub = `https://billing.stripe.com/p/login/test_6oE9CI4ae5Fn2tO7ss?prefilled_email=${email}`;

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns pt-2">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-9 mt-2">
                    {message && (
                        <div className={`notification ${message.type === 'success' ? 'is-primary' : 'is-danger'} mx-6`}>
                            {message.text}
                        </div>
                    )}
                    {subType === "Indépendant" ? (
                        <div className="notification is-info has-text-link has-text-centered mx-6">
                            <p>L'offre à laquelle vous avez souscrit ne propose pas la vente de produit.</p>
                            <p>Si vous souhaitez quand même vendre vos produits, veuillez souscrir à l'offre "Premium".</p>
                            <div className="is-flex is-justify-content-center">
                                <a className="button is-success is-responsive m-1 is-large has-text-link" href={manage_sub}>Changer d'abonnement</a>
                            </div>
                        </div>
                    ) : (
                        itemsForSale.length > 0 ? (
                            <ul>
                                {itemsForSale.map((item) => (
                                    <li key={item._id} className="m-1">
                                        <div className="columns has-background-info rounded-corners m-2">
                                            <div className="column is-3 my-3">
                                                <h2 className="has-text-link has-text-weight-semibold ml-3 is-size-5">
                                                    {item.name}
                                                    <span
                                                        className="icon ml-1 is-clickable"
                                                        onClick={() => openModal(`Indique le nom du produit.`)}>
                                                        <i className="fa-solid fa-circle-info has-text-success"></i>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className="column is-2 my-3">
                                                <p className="has-text-link has-text-weight-semibold is-size-5">
                                                    {item.price} €
                                                    <span
                                                        className="icon ml-1 is-clickable"
                                                        onClick={() => openModal(`Indique le prix du produit.`)}>
                                                        <i className="fa-solid fa-circle-info has-text-success"></i>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="column is-4 my-3">
                                                <p className="has-text-link has-text-weight-semibold is-size-5">
                                                    {item.brand_name}
                                                    <span
                                                        className="icon ml-1 is-clickable"
                                                        onClick={() => openModal(`Indique le nom de l'enseigne associé au produit.`)}>
                                                        <i className="fa-solid fa-circle-info has-text-success"></i>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="column is-3 m-1">
                                                <RedirectButton to={`/inventory/update/${item._id}`} label={<i className="fa-solid fa-pen"></i>} className="has-text-success has-background-link m-1" />
                                                <DeleteButton id={item._id} onDelete={() => handleDelete(item._id)} label={<i className="fa-solid fa-trash"></i>} className="has-text-link has-background-success my-1 ml-1" />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="notification is-info has-text-link has-text-centered mx-6">
                                Pour ajouter un article à vendre, il faut cocher la case "Produit à vendre" lors de la création d'un produit dans l'inventaire.
                            </div>
                        )
                    )}
                    {isModalOpen && (
                        <div className="modal is-active">
                            <div className="modal-background" onClick={closeModal}></div>
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title">Informations</p>
                                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                                </header>
                                <section className="modal-card-body">
                                    <p>{modalText}</p>
                                </section>
                                <footer className="modal-card-foot">
                                    <button className="button is-primary has-text-link" onClick={closeModal}>Fermer</button>
                                </footer>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}