import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function RedirectButton({ to, label, className }) {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(to);
  };

  return (
    <button className={`button ${className}`} onClick={handleRedirect}>
      {label}
    </button>
  );
}