import React from 'react';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import 'bulma/css/bulma.min.css';

export default function NavbarLog() {
  const location = useLocation();
  const token = localStorage.getItem('token');
  let decodedToken;

  if (token) {
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.error('Token decoding failed', error);
      decodedToken = null;
    }
  }

  const isActive = (path) => {
    const currentPath = location.pathname;
    // Vérifie si le chemin actuel correspond exactement ou est un sous-chemin (mais pas d'autres cas comme /reservation-history)
    return currentPath === path || currentPath.startsWith(`${path}/`);
  };

  const createLink = (path, activeImg, inactiveImg, label) => (
    <Link className={`button my-1 ${isActive(path) ? 'has-background-link has-text-success' : 'has-background-success has-text-link'}`} to={path}>
      <div className="columns is-vcentered">
        <div className="column is-4">
          <img className="cinquante-pourcent" src={isActive(path) ? activeImg : inactiveImg} alt={label} />
        </div>
        <div className="column is-8">
          <span className="nav-label">
            {label}
          </span>
        </div>
      </div>
    </Link>
  );

  let navLinks;

  if (!decodedToken) {
    navLinks = (
      <h1>Erreur lors de récupération de l'offre que vous avez choisi.</h1>
    );
  } else if (decodedToken.stripe.sub_type === "Entreprise") {
    navLinks = (
      <>
        {createLink('/dashboard', '/tab-in.png', '/tab-out.png', 'Tableau de bord')}
        {createLink('/brands', '/enseigne-in.png', '/enseigne-out.png', 'Consulter mes enseignes')}
        {createLink('/inventory', '/inventaire-in.png', '/inventaire-out.png', 'Inventaire')}
        {createLink('/reservation-history', '/histo-reservation-in.png', '/histo-reservation-out.png', 'Historique des réservations')}
        {createLink('/items-for-sale', '/article-in.png', '/article-out.png', 'Articles à vendre')}
        {createLink('/sales-history', '/histo-vente-in.png', '/histo-vente-out.png', 'Historique des ventes')}
        {createLink('/reservation', '/reservation-in.png', '/reservation-out.png', 'Réservations')}
      </>
    );
  } else if (decodedToken.stripe.sub_type === "Premium" || decodedToken.stripe.sub_type === "Indépendant") {
    navLinks = (
      <>
        {createLink('/dashboard', '/tab-in.png', '/tab-out.png', 'Tableau de bord')}
        {createLink('/brands', '/enseigne-in.png', '/enseigne-out.png', 'Consulter mon enseigne')}
        {createLink('/reservation-history', '/histo-reservation-in.png', '/histo-reservation-out.png', 'Historique des réservations')}
        {createLink('/items-for-sale', '/article-in.png', '/article-out.png', 'Articles à vendre')}
        {createLink('/sales-history', '/histo-vente-in.png', '/histo-vente-out.png', 'Historique des ventes')}
        {createLink('/reservation', '/reservation-in.png', '/reservation-out.png', 'Réservations')}
      </>
    );
  } else {
    navLinks = (
      <h1>Erreur lors de récupération de l'offre que vous avez choisi.</h1>
    );
  }

  return (
    <div>
      {navLinks}
    </div>
  );
}