import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';

export default function RefuseReservation() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [reservation, setReservation] = useState(null);
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchReservation = async () => {
            try {
                const response = await axios.get(`https://drimo.alwaysdata.net/api/reservation/${id}`);
                setReservation(response.data);
            } catch (error) {
                console.error('Error fetching reservation:', error);
            }
        };

        fetchReservation();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!reason.trim()) {
            setError('Le champ de justification ne peut pas être vide.');
            return;
        }

        try {
            await axios.post(`https://drimo.alwaysdata.net/api/reservation/refuse/${id}`, { reason });
            navigate('/reservation');
        } catch (error) {
            console.error('Error refusing reservation:', error);
            setError('Une erreur est survenue lors du refus de la réservation.');
        }
    };

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6 has-background-info rounded-corners">
                    {reservation ? (
                        <>
                            <h3 className="pt-2 pb-6 has-text-link has-text-weight-bold has-text-centered is-size-4">
                                Refuser la réservation
                            </h3>
                            <p className="has-text-link has-text-centered">
                                Vous avez refusé la réservation de <strong>{reservation.customerFName} {reservation.customerLName}</strong> pour la prestation <strong>{reservation.prestation[0].name}</strong> au prix de <strong>{reservation.prestation[0].price}€</strong>, à la date du <strong>{reservation.dateTime[0].date}</strong>.
                            </p>
                            <div className="is-flex is-justify-content-center m-1">
                                <button
                                    className="button has-background-success has-text-link mt-4"
                                    onClick={() => navigate('/reservation')}
                                >
                                    Annuler
                                </button>
                            </div>
                            <div className="mt-6">
                                <h4 className="has-text-link">Merci de justifier pourquoi vous avez refusé la réservation :</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="field">
                                        <textarea
                                            className="textarea has-text-success has-background-link"
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            placeholder="Expliquez la raison du refus..."
                                            required
                                        ></textarea>
                                    </div>
                                    {error && <p className="has-text-danger">{error}</p>}
                                    <div className="is-flex is-justify-content-center mt-2">
                                        <button type="submit" className="button is-success has-text-link">Envoyer</button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <p className="has-text-link">Chargement des détails de la réservation...</p>
                    )}
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}