import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import { jwtDecode } from 'jwt-decode';

export default function UpdateInventory() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [brands, setBrands] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        quantity: '',
        photo: [],
        reminder: false,
        minimalQuantity: '',
        sell: false,
        price: '',
        brand_id: ''
    });
    const [message, setMessage] = useState(null);

    useEffect(() => {
        async function fetchBrandsAndInventory() {
            try {
                const token = localStorage.getItem('token');
                const decodedToken = jwtDecode(token);
                const user_id = decodedToken.userId;

                // Fetch brands
                const brandsResponse = await axios.get(`https://drimo.alwaysdata.net/api/brands`, {
                    params: { user_id },
                });
                const userBrands = brandsResponse.data.filter(brand => brand.user_id === user_id);
                setBrands(userBrands);

                // Fetch inventory data
                const inventoryResponse = await axios.get(`https://drimo.alwaysdata.net/api/inventories/${id}`);
                const inventoryData = inventoryResponse.data;

                // Set the fetched inventory data to formData
                setFormData({
                    ...inventoryData,
                    brand_id: inventoryData.brand_id || userBrands[0]?._id || '',
                    reminder: inventoryData.reminder || false,
                    sell: inventoryData.sell || false,
                });

            } catch (error) {
                console.error('Error fetching brands or inventory:', error);
            }
        }

        fetchBrandsAndInventory();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked, // Store as a boolean
            }));
        } else if (name === 'photo') {
            setFormData((prevData) => ({
                ...prevData,
                photo: [...prevData.photo.slice(0, -1), ...files]
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const removePhoto = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            photo: prevData.photo.filter((_, i) => i !== index)
        }));
    };

    const addPhotoField = () => {
        setFormData((prevData) => ({
            ...prevData,
            photo: [...prevData.photo, '']
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validation des champs requis
        if (!formData.name || !formData.quantity || !formData.brand_id) {
            alert('Veuillez remplir tous les champs obligatoires.');
            return;
        }
    
        if (formData.reminder && !formData.minimalQuantity) {
            alert('Veuillez spécifier un stock minimum avant rappel lorsque le rappel est activé.');
            return;
        }
    
        if (formData.sell && !formData.price) {
            alert('Veuillez spécifier un prix lorsque le produit est à vendre.');
            return;
        }
    
        // Préparation des données pour l'envoi
        const dataToSubmit = new FormData();
    
        // Ajouter les champs de formulaire
        dataToSubmit.append('name', formData.name || '');
        dataToSubmit.append('quantity', formData.quantity || '');
        dataToSubmit.append('brand_id', formData.brand_id || '');
        dataToSubmit.append('reminder', formData.reminder ? 'true' : 'false');
        dataToSubmit.append('minimalQuantity', formData.reminder ? formData.minimalQuantity : '');
        dataToSubmit.append('sell', formData.sell ? 'true' : 'false');
        dataToSubmit.append('price', formData.sell ? formData.price : '');
    
        // Ajouter les photos
        formData.photo.forEach((file, index) => {
            if (file instanceof File) {
                dataToSubmit.append('photo', file);
            }
        });
    
        // Debugging : Afficher les données avant l'envoi
        console.log("Form Data being submitted:");
        for (let pair of dataToSubmit.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
    
        try {
            const response = await axios.put(`https://drimo.alwaysdata.net/api/inventories/${id}`, dataToSubmit, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Inventory updated successfully:', response.data);
            setMessage({ type: 'success', text: 'Inventaire mis à jour avec succès ! Redirection en cours...' });
            setTimeout(() => {
                navigate('/inventory');
            }, 2000);
        } catch (error) {
            console.error('Error updating inventory:', error);
            setMessage({ type: 'error', text: 'Erreur lors de la mise à jour de l\'inventaire. Veuillez réessayer plus tard...' });
        }
    };    
    
    console.log('ID being used:', id);
    console.log('formData', formData);

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6 has-background-info rounded-corners">
                    <h3 className="pt-2 pb-6 has-text-link has-text-weight-bold has-text-centered is-size-4">Modifier un inventaire :</h3>
                    <form onSubmit={handleSubmit} className="px-2">
                        <div className="field">
                            <label className="label has-text-link">
                                Nom du produit
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label has-text-link">
                                Quantité
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="number"
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label has-text-link">
                                Enseigne associée
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="brand_id"
                                        value={formData.brand_id || ""}
                                        onChange={handleChange}
                                        required
                                        className="has-background-link has-text-success">
                                        <option value="">Sélectionnez l'enseigne associée au produit</option>
                                        {brands.map(brand => (
                                            <option key={brand._id} value={brand._id}>
                                                {brand.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label has-text-link">
                                Photos
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            {formData.photo.map((photo, idx) => (
                                <div key={idx} className="file has-name is-success m-1">
                                    <label className="file-label">
                                        <input
                                            className="file-input"
                                            type="file"
                                            name="photo"
                                            accept="image/png"
                                            onChange={handleChange}
                                            required={idx === 0}
                                        />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-upload has-text-link"></i>
                                            </span>
                                            <span className="file-label has-text-link">Choisissez un fichier…</span>
                                        </span>
                                        {photo && photo instanceof File && (
                                            <span className="file-name has-background-link has-text-success">
                                                {photo.name}
                                            </span>
                                        )}
                                    </label>
                                    <button type="button" className="button is-danger ml-1" onClick={() => removePhoto(idx)}>
                                        <span className="icon text-dark">
                                            <i className="fa-solid fa-trash"></i>
                                        </span>
                                    </button>
                                </div>
                            ))}
                            <button type="button" className="button is-success has-text-link m-1" onClick={addPhotoField}>
                                Ajouter une photo
                            </button>
                        </div>

                        <div className="field">
                            <label className="label has-text-link">
                                <input
                                    type="checkbox"
                                    name="reminder"
                                    checked={formData.reminder}
                                    onChange={handleChange}
                                />
                                &nbsp; Activer le rappel de stock minimum
                            </label>
                            {formData.reminder ? (
                                <div className="control">
                                    <h3 className="label has-text-link">
                                        Stock minimum avant rappel
                                    </h3>
                                    <input
                                        className="input has-background-link has-text-success"
                                        type="number"
                                        name="minimalQuantity"
                                        value={formData.minimalQuantity}
                                        onChange={handleChange}
                                        placeholder="Stock minimum"
                                    />
                                </div>
                            ) : (
                                <div className="control">
                                    <h3 className="label has-text-link">
                                        Stock minimum avant rappel
                                    </h3>
                                    <input
                                        className="input has-background-link has-text-success"
                                        type="number"
                                        name="minimalQuantity"
                                        value={formData.minimalQuantity = ''}
                                        onChange={handleChange}
                                        placeholder="Stock minimum"
                                        disabled
                                    />
                                </div>
                            )}
                        </div>

                        <div className="field">
                            <label className="label has-text-link">
                                <input
                                    type="checkbox"
                                    name="sell"
                                    checked={formData.sell}
                                    onChange={handleChange}
                                />
                                &nbsp; Produit à vendre
                            </label>
                            {formData.sell ? (
                                <div className="control">
                                    <h3 className="label has-text-link">Prix</h3>
                                    <input
                                        className="input has-background-link has-text-success"
                                        type="number"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        placeholder="Prix"
                                    />
                                </div>
                            ) : (
                                <div className="control">
                                    <h3 className="label has-text-link">Prix</h3>
                                    <input
                                        className="input has-background-link has-text-success"
                                        type="number"
                                        name="price"
                                        value={formData.price = ''}
                                        onChange={handleChange}
                                        placeholder="Prix"
                                        disabled
                                    />
                                </div>
                            )}
                        </div>
                        {message && (
                            <div className={`notification ${message.type === 'success' ? 'is-primary has-text-link' : 'is-danger has-text-link'} mx-6`}>
                                {message.text}
                            </div>
                        )}
                        <div className="control py-3 has-text-centered">
                            <button type="submit" className="button is-success has-text-link">Mettre à jour</button>
                        </div>
                    </form>
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}