import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import RedirectButton from '../components/RedirectButton';
import { jwtDecode } from 'jwt-decode';

export default function ReservationHistory() {
    const [reservationHistory, setReservationHistory] = useState([]);
    const [modalContent, setModalContent] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [availableMonths, setAvailableMonths] = useState([]);

    useEffect(() => {
        async function fetchReservationHistory() {
            try {
                const token = localStorage.getItem('token');
                const decodedToken = jwtDecode(token);
                const user_id = decodedToken.userId;
                
                const response = await axios.get('https://drimo.alwaysdata.net/api/reservation-history', {
                    params: { user_id, month: selectedMonth }
                });
                setReservationHistory(response.data);

                if (selectedMonth === '') {
                    // Extraire les mois disponibles pour le dropdown si aucun mois spécifique n'est sélectionné
                    const months = [...new Set(response.data.map(reservation => {
                        const [day, month, year] = reservation.dateTime[0].date.split('/');
                        return `${month}/${year}`;
                    }))];
                    setAvailableMonths(months);
                }
            } catch (error) {
                console.error('Error fetching reservation history:', error);
            }
        }

        fetchReservationHistory();
    }, [selectedMonth]);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const formatDateTime = (dateTimeObj) => {
        const { day, hour, minute, date } = dateTimeObj;
        return `${day} ${date}, à ${hour}:${minute}`;
    };

    const openModal = (content) => {
        setModalContent(content);
    };

    const closeModal = () => {
        setModalContent(null);
    };

    const generatePDF = async () => {
        try {
            const response = await axios.post('https://drimo.alwaysdata.net/api/generate-pdf', {
                templateType: 'reservation',
                data: reservationHistory.map(reservation => ({
                    clientName: `${reservation.customerFName} ${reservation.customerLName}`,
                    dateTime: reservation.dateTime[0]?.date,
                    prestation: reservation.prestation[0]?.name || 'N/A',
                    price: reservation.prestation[0]?.price || 0,
                    brandId: reservation.brand_id,
                    brandName: reservation.brand_name,
                    brandAddress: reservation.brand_address,
                })),
            });

            if (response.data.status === 'success') {
                alert('PDF généré avec succès');
            } else {
                alert('Échec de la génération du PDF');
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
            alert('Erreur lors de la génération du PDF');
        }
    };

    console.log(selectedMonth);

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6">
                    <div className="is-flex is-justify-content-end m-1 mb-3">
                        <RedirectButton to="/reservation-history/pdf" label="Mes PDF" className="is-success has-text-link" />
                    </div>
                    <div className="level">
                        <div className="level-left m-1 mb-4">
                            <div className="select level-item is-success has-text-link">
                                <select onChange={handleMonthChange} value={selectedMonth} className="has-text-weight-medium">
                                    <option value="">Tout prendre</option>
                                    {availableMonths.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="level-right m-1 mb-4">
                            <button className="level-item button is-success has-text-link" onClick={generatePDF}>
                                Générer le PDF
                            </button>
                        </div>
                    </div>
                    {reservationHistory.length === 0 ? (
                        <div className="notification is-info has-text-centered has-text-link">
                            Vous n'avez pas de réservations passées.
                        </div>
                    ) : (
                        <ul>
                            {reservationHistory.map((reservation, index) => (
                                <li key={index} className="mb-4 has-background-info rounded-corners">
                                    <div className="columns">
                                        <div className="column is-3 ml-6">
                                            <p className="has-text-link has-text-weight-semibold">
                                                <span className="icon is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Nom :</strong> {reservation.customerLName}</p>
                                                        <p><strong>Prénom :</strong> {reservation.customerFName}</p>
                                                        <p><strong>Email :</strong> {reservation.customerEmail}</p>
                                                        <p><strong>Téléphone :</strong> {reservation.customerPhone}</p>
                                                    </>
                                                )}>
                                                    Client
                                                    <i className="fas fa-user ml-1 has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-4 has-text-link has-text-weight-semibold">
                                            <span className="icon is-clickable" onClick={() => openModal(
                                                <p><strong>{formatDateTime(reservation.dateTime[0])}</strong></p>
                                            )}>
                                                {reservation.dateTime[0].date}
                                                <i className="fa-regular fa-calendar-days has-text-success ml-1"></i>
                                            </span>
                                        </div>
                                        <div className="column is-3 has-text-link has-text-weight-semibold">
                                            <span className="icon is-clickable" onClick={() => openModal(
                                                <>
                                                    <p><strong>Prestation :</strong> {reservation.prestation[0]?.name}</p>
                                                    <p><strong>Prix :</strong> {reservation.prestation[0]?.price}€</p>
                                                    <p><strong>Commentaire :</strong> {reservation.comment}</p>
                                                    <p><strong>Enseigne :</strong> {reservation.brand_name}</p>
                                                </>
                                            )}>
                                                Détails
                                                <i className="fa-solid fa-circle-info has-text-success ml-1"></i>
                                            </span>
                                        </div>
                                        <div className="column is-1">
                                            {reservation.payment_status === true ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement a été accepté</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-check" style={{ color: "#1338BE" }}></i>
                                                    </span>
                                                </p>
                                            ) : reservation.payment_status === null ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement est en attente</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-pause" style={{ color: "#FEE135" }}></i>
                                                    </span>
                                                </p>
                                            ) : reservation.payment_status === false ? (
                                                <p className="is-clickable" onClick={() => openModal(
                                                    <>
                                                        <p><strong>Le paiement a été refusé</strong></p>
                                                    </>
                                                )}>
                                                    <span className="icon m-1">
                                                        <i className="fa-solid fa-circle-xmark" style={{ color: "#BF0A30" }}></i>
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="has-text-link">Erreur</p>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}

                    {modalContent && (
                        <div className="modal is-active">
                            <div className="modal-background" onClick={closeModal}></div>
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title">Informations</p>
                                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                                </header>
                                <section className="modal-card-body">
                                    {modalContent}
                                </section>
                                <footer className="modal-card-foot">
                                    <button className="button is-primary has-text-link" onClick={closeModal}>Fermer</button>
                                </footer>
                            </div>
                        </div>
                    )}
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}