import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import { useParams, useNavigate } from 'react-router-dom';

export default function UpdateReservation() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        customerFName: '',
        customerLName: '',
        customerEmail: '',
        customerPhone: '',
        dateTime: { day: '', hour: '', minute: '', date: '' },
        prestation: { name: '', price: 0 },
        comment: '',
        brand_id: '',
        reviewReservation: null,
        starReservation: null,
        status: true,
        customer_id: null,
        payment_status: null
    });
    const [brandName, setBrandName] = useState('');
    const [openDates, setOpenDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);
    const [availableMinutes, setAvailableMinutes] = useState([]);
    const [datesByDay, setDatesByDay] = useState({});
    const [prestations, setPrestations] = useState([]);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const fetchReservationAndBrand = async () => {
            try {
                const reservationResponse = await axios.get(`https://drimo.alwaysdata.net/api/reservation/${id}`);
                const reservationData = reservationResponse.data;

                setFormData({
                    customerFName: reservationData.customerFName,
                    customerLName: reservationData.customerLName,
                    customerEmail: reservationData.customerEmail,
                    customerPhone: reservationData.customerPhone,
                    dateTime: reservationData.dateTime[0],
                    prestation: reservationData.prestation[0],
                    comment: reservationData.comment,
                    brand_id: reservationData.brand_id,
                    reviewReservation: reservationData.reviewReservation,
                    starReservation: reservationData.starReservation,
                    status: reservationData.status,
                    customer_id: reservationData.customer_id,
                    payment_status: reservationData.payment_status
                });

                const brandResponse = await axios.get(`https://drimo.alwaysdata.net/api/brands/${reservationData.brand_id}`);
                const brandData = brandResponse.data;

                setBrandName(brandData.name);
                const openDays = brandData.openDate.filter(day => !day.isClosed);
                setOpenDates(openDays);
                setPrestations(brandData.prestations || []);

                generateDatesForNext90Days(openDays);
            } catch (error) {
                console.error('Error fetching reservation or brand:', error);
            }
        };

        fetchReservationAndBrand();
    }, [id]);

    const generateDatesForNext90Days = (openDays) => {
        const daysOfWeek = openDays.map(day => day.day.toLowerCase());
        const datesByDay = {};

        // Initialize datesByDay with empty arrays for each open day
        openDays.forEach(day => {
            datesByDay[day.day] = [];
        });

        const today = new Date();

        for (let i = 1; i <= 90; i++) {
            const nextDate = new Date(today);
            nextDate.setDate(today.getDate() + i);

            // Get the day of the week in French
            const dayOfWeek = nextDate.toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();

            // Normalize the day of the week to match the openDays array
            const normalizedDay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

            if (daysOfWeek.includes(dayOfWeek)) {
                const formattedDate = nextDate.toLocaleDateString('fr-FR'); // Format as dd/mm/yyyy
                datesByDay[normalizedDay].push(formattedDate);
            }
        }

        console.log("Generated datesByDay dictionary:", datesByDay);
        setDatesByDay(datesByDay);
    };

    const handleDayChange = (e) => {
        const selectedDay = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, day: selectedDay, date: '' }
        }));

        setAvailableDates(datesByDay[selectedDay] || []);
        console.log("Day selected:", selectedDay);
        console.log("Available dates for selected day:", datesByDay[selectedDay] || []);
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, date: selectedDate }
        }));

        const dayOfWeek = new Date(selectedDate.split('/').reverse().join('-')).toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();
        const dayDetails = openDates.find(day => day.day.toLowerCase() === dayOfWeek);

        if (dayDetails) {
            const openHour = parseInt(dayDetails.open.split(':')[0], 10);
            const closeHour = parseInt(dayDetails.close.split(':')[0], 10);

            const hours = [];
            for (let i = openHour; i <= closeHour; i++) {
                hours.push(i.toString().padStart(2, '0'));
            }
            setAvailableHours(hours);
            setAvailableMinutes([]);  // Reset minutes when a new hour is selected
        } else {
            setAvailableHours([]);
            setAvailableMinutes([]);
        }

        console.log("Date selected:", selectedDate);
        console.log("Available hours for selected date:", dayDetails ? availableHours : []);
    };

    const handleHourChange = (e) => {
        const selectedHour = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, hour: selectedHour, minute: '' }
        }));

        const selectedDate = formData.dateTime.date;
        const dayOfWeek = new Date(selectedDate.split('/').reverse().join('-')).toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();
        const dayDetails = openDates.find(day => day.day.toLowerCase() === dayOfWeek);

        if (dayDetails) {
            const closeHour = parseInt(dayDetails.close.split(':')[0], 10);
            const closeMinute = parseInt(dayDetails.close.split(':')[1], 10);

            const minutes = [];
            if (parseInt(selectedHour, 10) === closeHour) {
                for (let i = 0; i <= closeMinute; i += 15) {
                    minutes.push(i.toString().padStart(2, '0'));
                }
            } else {
                minutes.push('00', '15', '30', '45');
            }
            setAvailableMinutes(minutes);
        } else {
            setAvailableMinutes([]);
        }

        console.log("Hour selected:", selectedHour);
        console.log("Available minutes for selected hour:", availableMinutes);
    };

    const handleMinuteChange = (e) => {
        const selectedMinute = e.target.value;
        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, minute: selectedMinute }
        }));

        console.log("Minute selected:", selectedMinute);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Ensure that prestation is an array even if it contains only one object
        const formattedPrestation = Array.isArray(formData.prestation)
            ? formData.prestation.map(p => ({
                name: p.name,
                price: parseFloat(p.price)  // Ensure price is treated as a double
            }))
            : [{
                name: formData.prestation.name,
                price: parseFloat(formData.prestation.price)  // Ensure price is treated as a double
            }];
    
        // Ensure brand_id and customer_id are strings as per the schema
        const formattedBrandId = String(formData.brand_id);
        const formattedCustomerId = formData.customer_id ? String(formData.customer_id) : null;
    
        const updatedData = {
            id: String(id), // Ensure ID is treated as a string
            customerFName: formData.customerFName,
            customerLName: formData.customerLName,
            customerEmail: formData.customerEmail,
            customerPhone: formData.customerPhone,
            dateTime: [formData.dateTime], // Ensure dateTime is an array of objects as per the schema
            prestation: formattedPrestation,
            comment: formData.comment,
            brand_id: formattedBrandId,
            reviewReservation: formData.reviewReservation,
            starReservation: formData.starReservation,
            status: formData.status,
            customer_id: formattedCustomerId,
            payment_status: formData.payment_status
        };
    
        console.log("Data to submit:", updatedData);
    
        try {
            await axios.put(`https://drimo.alwaysdata.net/api/reservation/${id}`, updatedData);
            setMessage({ type: 'success', text: 'Réservation mise à jour avec succès!' });
            setTimeout(() => {
                navigate('/reservation');
            }, 2000);
        } catch (error) {
            console.error('Error updating reservation:', error);
            setMessage({ type: 'error', text: 'Erreur lors de la mise à jour de la réservation.' });
        }
    };    

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6 has-background-info rounded-corners">
                    <h3 className="pt-2 pb-6 has-text-link has-text-weight-bold has-text-centered is-size-4">
                        Mettre à jour la réservation :
                    </h3>
                    <form onSubmit={handleSubmit} className="px-2">
                        <div className="field">
                            <label className="label has-text-link">Enseigne</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    value={brandName}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Nom du client</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="customerLName"
                                    value={formData.customerLName}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Prénom du client</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="customerFName"
                                    value={formData.customerFName}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Email du client</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="email"
                                    name="customerEmail"
                                    value={formData.customerEmail}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Téléphone</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="customerPhone"
                                    value={formData.customerPhone}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Jour de la semaine</label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="day"
                                        value={formData.dateTime.day || ''}
                                        onChange={handleDayChange}
                                        required
                                    >
                                        <option value="">Sélectionnez un jour</option>
                                        {openDates.map(date => (
                                            <option key={date.day} value={date.day}>
                                                {date.day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Date</label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="date"
                                        value={formData.dateTime.date || ''}
                                        onChange={handleDateChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une date</option>
                                        {availableDates.map(date => (
                                            <option key={date} value={date}>
                                                {date}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Heure</label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="hour"
                                        value={formData.dateTime.hour || ''}
                                        onChange={handleHourChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une heure</option>
                                        {availableHours.map(hour => (
                                            <option key={hour} value={hour}>
                                                {hour}:00
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Minute</label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="minute"
                                        value={formData.dateTime.minute || ''}
                                        onChange={handleMinuteChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une minute</option>
                                        {availableMinutes.map(minute => (
                                            <option key={minute} value={minute}>
                                                {minute}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Prestation</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="prestation.name"
                                    value={formData.prestation.name || ''}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Prix</label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="prestation.price"
                                    value={formData.prestation.price || ''}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">Commentaire</label>
                            <div className="control">
                                <textarea
                                    className="textarea has-background-link has-text-success"
                                    name="comment"
                                    value={formData.comment}
                                    disabled
                                ></textarea>
                            </div>
                        </div>
                        {message && (
                            <div className={`notification ${message.type === 'success' ? 'is-primary' : 'is-danger'} has-text-link mx-6`}>
                                {message.text}
                            </div>
                        )}
                        <div className="control py-3 has-text-centered">
                            <button type="submit" className="button is-success has-text-link">Mettre à jour</button>
                        </div>
                    </form>
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}