import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export default function CreateReservation() {
    const [brands, setBrands] = useState([]);
    const [prestations, setPrestations] = useState([]);
    const [openDates, setOpenDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);
    const [availableMinutes, setAvailableMinutes] = useState([]);
    const [datesByDay, setDatesByDay] = useState({});
    const [formData, setFormData] = useState({
        customerFName: '',
        customerLName: '',
        customerEmail: '',
        customerPhone: '',
        dateTime: { day: '', hour: '', minute: '', date: '' },
        prestation: { name: '', price: 0 },
        comment: '',
        brand_id: '',
        reviewReservation: null,
        starReservation: null,
        status: true,
        customer_id: null,
        payment_status: null
    });
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    // Fetching brands and their respective opening dates and prestations
    useEffect(() => {
        const fetchBrandsAndPrestations = async () => {
            try {
                const token = localStorage.getItem('token');
                const decodedToken = jwtDecode(token);
                const user_id = decodedToken.userId;

                const brandsResponse = await axios.get(`https://drimo.alwaysdata.net/api/brands`, { params: { user_id } });

                setBrands(brandsResponse.data);

                // Vérifiez si une enseigne est déjà sélectionnée, sinon, choisissez la première par défaut
                if (brandsResponse.data.length > 0 && !formData.brand_id) {
                    const firstBrandId = brandsResponse.data[0]._id;
                    setFormData(prevData => ({
                        ...prevData,
                        brand_id: firstBrandId,
                    }));

                    await fetchOpenDates(firstBrandId);
                    await fetchPrestations(firstBrandId);
                } else if (formData.brand_id) {
                    // Si une enseigne est déjà sélectionnée, rechargez ses données
                    await fetchOpenDates(formData.brand_id);
                    await fetchPrestations(formData.brand_id);
                }

                if (openDates.length > 0) {
                    generateDatesForNext90Days(); // Générer les dates des 90 prochains jours en fonction des jours d'ouverture
                }

            } catch (error) {
                console.error('Error fetching brands or prestations:', error);
            }
        };

        fetchBrandsAndPrestations();
    }, [openDates, availableDates]);

    // Fetch opening dates for the selected brand
    const fetchOpenDates = async (brand_id) => {
        try {
            const response = await axios.get(`https://drimo.alwaysdata.net/api/brands/${brand_id}`);
            const brand = response.data;

            if (brand && brand.openDate) {
                const openDays = brand.openDate.filter(day => !day.isClosed);
                setOpenDates(openDays);
                generateDatesForNext90Days(openDays); // Generate dates based on open days
            }
        } catch (error) {
            console.error('Error fetching open dates:', error);
            setOpenDates([]);
        }
    };

    // Fetch prestations for the selected brand
    const fetchPrestations = async (brand_id) => {
        try {
            const response = await axios.get(`https://drimo.alwaysdata.net/api/brands/${brand_id}`);
            const brand = response.data;

            if (brand && Array.isArray(brand.prestations)) {
                setPrestations(brand.prestations);
            } else {
                console.error('Prestations not found or not in expected format.');
                setPrestations([]);
            }
        } catch (error) {
            console.error('Error fetching prestations:', error);
            setPrestations([]);
        }
    };

    // Generate dates for the next 90 days based on the open days
    const generateDatesForNext90Days = () => {
        const daysOfWeek = openDates.map(day => day.day); // Extraire les jours d'ouverture de openDates
        const datesByDay = {};

        // Initialiser l'objet datesByDay avec les jours d'ouverture
        daysOfWeek.forEach(day => {
            datesByDay[day] = [];
        });

        const today = new Date();

        for (let i = 1; i <= 90; i++) {
            const nextDate = new Date(today);
            nextDate.setDate(today.getDate() + i);

            // Assurez-vous que dayOfWeek est en français
            const dayOfWeek = nextDate.toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase(); // Obtenir le jour de la semaine en français

            // Normalisation des formats de jour (e.g., 'lundi' au lieu de 'Lundi')
            const normalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

            if (daysOfWeek.includes(normalizedDayOfWeek)) {
                const formattedDate = nextDate.toLocaleDateString('fr-FR'); // Format 'dd/mm/yyyy'
                datesByDay[normalizedDayOfWeek].push(formattedDate); // Ajouter la date au jour correspondant
            }
        }
        setDatesByDay(datesByDay); // Stocker les dates par jour
    };

    // Handle change in the day of the week
    const handleDayChange = (e) => {
        const selectedDay = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, day: selectedDay, date: '' }
        }));
        setAvailableDates(datesByDay[selectedDay] || []); // Afficher les dates correspondant au jour sélectionné
    };

    // Handle change in the date
    const handleDateChange = (e) => {
        const selectedDate = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, date: selectedDate }
        }));

        // Extraire le jour de la semaine en français à partir de la date sélectionnée
        const dayOfWeek = new Date(selectedDate.split('/').reverse().join('-')).toLocaleDateString('fr-FR', { weekday: 'long' });

        // Trouver les heures d'ouverture et de fermeture pour le jour sélectionné
        const dayDetails = openDates.find(day => day.day.toLowerCase() === dayOfWeek.toLowerCase());

        if (dayDetails) {
            const openHour = parseInt(dayDetails.open.split(':')[0]);
            const closeHour = parseInt(dayDetails.close.split(':')[0]);

            // Générer les heures disponibles entre open et close
            const hours = [];
            for (let i = openHour; i <= closeHour; i++) {
                hours.push(i.toString().padStart(2, '0'));
            }
            setAvailableHours(hours);  // Mettre à jour les heures disponibles

            // Réinitialiser les minutes disponibles lorsque l'heure change
            setAvailableMinutes([]);
        } else {
            setAvailableHours([]);
            setAvailableMinutes([]);
        }
    };

    const handleHourChange = (e) => {
        const selectedHour = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, hour: selectedHour, minute: '' }
        }));

        const selectedDate = formData.dateTime.date;
        const dayOfWeek = new Date(selectedDate.split('/').reverse().join('-')).toLocaleDateString('fr-FR', { weekday: 'long' });

        // Trouver les heures d'ouverture pour le jour sélectionné
        const dayDetails = openDates.find(day => day.day.toLowerCase() === dayOfWeek.toLowerCase());

        if (dayDetails) {
            const closeHour = parseInt(dayDetails.close.split(':')[0]);
            const closeMinute = parseInt(dayDetails.close.split(':')[1]);

            const minutes = [];
            if (parseInt(selectedHour) === closeHour) {
                for (let i = 0; i <= closeMinute; i += 15) {
                    minutes.push(i.toString().padStart(2, '0'));
                }
            } else {
                minutes.push('00', '15', '30', '45');
            }
            setAvailableMinutes(minutes);  // Mettre à jour les minutes disponibles
        } else {
            setAvailableMinutes([]);
        }
    };

    // Handle change in the brand
    const handleBrandChange = (e) => {
        const brand_id = e.target.value;

        setFormData(prevData => ({
            ...prevData,
            brand_id,  // Mise à jour de l'ID de l'enseigne sélectionnée
            prestation: { name: '', price: 0 }  // Réinitialiser la prestation sélectionnée
        }));

        if (brand_id) {
            fetchOpenDates(brand_id);  // Charger les dates d'ouverture pour la nouvelle enseigne sélectionnée
            fetchPrestations(brand_id);  // Charger les prestations pour la nouvelle enseigne sélectionnée
        } else {
            setOpenDates([]);
            setPrestations([]);
        }
    };

    // Handle change in the minute
    const handleMinuteChange = (e) => {
        const selectedMinute = e.target.value;
        setFormData(prevData => ({
            ...prevData,
            dateTime: { ...prevData.dateTime, minute: selectedMinute }
        }));
    };

    // Handle change in the prestation
    const handlePrestationChange = (e) => {
        const selectedPrestationName = e.target.value;
        const selectedPrestation = prestations.find(p => p.name === selectedPrestationName);

        setFormData(prevData => ({
            ...prevData,
            prestation: selectedPrestation ? { name: selectedPrestation.name, price: parseFloat(selectedPrestation.price) } : { name: '', price: 0 }
        }));
    };

    // Handle change in other form fields
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const { day, hour, minute, date } = formData.dateTime;

        if (!day || !hour || minute === '' || !date) {
            setMessage({ type: 'error', text: 'Veuillez sélectionner une date et une heure valides.' });
            return;
        }

        const formattedPrestation = {
            ...formData.prestation,
            price: String(formData.prestation.price)
        };

        const dataToSubmit = {
            customerFName: formData.customerFName || null,
            customerLName: formData.customerLName || null,
            customerEmail: formData.customerEmail || null,
            customerPhone: formData.customerPhone || null,
            dateTime: [{ day, hour, minute, date }],
            prestation: [formattedPrestation],
            comment: formData.comment || null,
            reviewReservation: formData.reviewReservation || null,
            starReservation: formData.starReservation !== null ? parseInt(formData.starReservation, 10) : null,
            status: formData.status === true || formData.status === false ? formData.status : null,
            brand_id: formData.brand_id,
            customer_id: formData.customer_id || null,
            payment_status: formData.payment_status || null
        };

        try {
            const response = await axios.post('https://drimo.alwaysdata.net/api/reservation', dataToSubmit);

            if (response.status === 201) {
                setMessage({ type: 'success', text: 'Réservation créée avec succès!' });
                setTimeout(() => {
                    navigate('/reservation');
                }, 2000);
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error('Error during reservation creation:', error);

            setMessage({ type: 'error', text: 'Erreur lors de la création de la réservation.' });
        }
    };

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6 has-background-info rounded-corners">
                    <h3 className="pt-2 pb-6 has-text-link has-text-weight-bold has-text-centered is-size-4">
                        Créer une réservation :
                    </h3>
                    <form onSubmit={handleSubmit} className="px-2">
                        <div className="field">
                            <label className="label has-text-link">
                                Enseigne
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="brand_id"
                                        value={formData.brand_id}
                                        onChange={handleBrandChange}
                                        required
                                    >
                                        <option value="">Sélectionnez votre enseigne</option>
                                        {brands.map(brand => (
                                            <option key={brand._id} value={brand._id}>
                                                {brand.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Nom du client
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="customerLName"
                                    value={formData.customerLName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Prénom du client
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="customerFName"
                                    value={formData.customerFName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Email du client
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="email"
                                    name="customerEmail"
                                    value={formData.customerEmail}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Téléphone
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="customerPhone"
                                    value={formData.customerPhone}
                                    onChange={handleChange}
                                    required
                                    maxLength="10"
                                    pattern="\d{10}"
                                    placeholder="Ex: 0612345678"
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Jour de la semaine
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="day"
                                        value={formData.dateTime.day}
                                        onChange={handleDayChange}
                                        required
                                    >
                                        <option value="">Sélectionnez un jour</option>
                                        {openDates.map(date => (
                                            <option key={date.day} value={date.day}>
                                                {date.day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Date
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="date"
                                        value={formData.dateTime.date}
                                        onChange={handleDateChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une date</option>
                                        {availableDates.map(date => (
                                            <option key={date} value={date}>
                                                {date}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Heure
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="hour"
                                        value={formData.dateTime.hour}
                                        onChange={handleHourChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une heure</option>
                                        {availableHours.map(hour => (
                                            <option key={hour} value={hour}>
                                                {hour}:00
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Minute
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="minute"
                                        value={formData.dateTime.minute}
                                        onChange={handleMinuteChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une minute</option>
                                        {availableMinutes.map(minute => (
                                            <option key={minute} value={minute}>
                                                {minute}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Prestation
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <div className="select is-success">
                                    <select
                                        name="prestation.name"
                                        value={formData.prestation.name || ''}
                                        onChange={handlePrestationChange}
                                        required
                                    >
                                        <option value="">Sélectionnez une prestation</option>
                                        {prestations.map(prestation => (
                                            <option key={prestation.name} value={prestation.name}>
                                                {prestation.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Prix
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <input
                                    className="input has-background-link has-text-success"
                                    type="text"
                                    name="prestation.price"
                                    value={formData.prestation.price || ''}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label has-text-link">
                                Commentaire
                                <span className="icon">
                                    <i className="fa-solid fa-asterisk has-text-success"></i>
                                </span>
                            </label>
                            <div className="control">
                                <textarea
                                    className="textarea has-background-link has-text-success"
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                        {message && (
                            <div className={`notification ${message.type === 'success' ? 'is-primary' : 'is-danger'} has-text-link mx-6`}>
                                {message.text}
                            </div>
                        )}
                        <div className="control py-3 has-text-centered">
                            <button type="submit" className="button is-success has-text-link">Créer</button>
                        </div>
                    </form>
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}