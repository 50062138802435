import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import RedirectButton from '../components/RedirectButton';
import DeleteButton from '../components/DeleteButton';
import { jwtDecode } from 'jwt-decode';

export default function Inventory() {
    const [inventories, setInventories] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');

    useEffect(() => {
        const fetchInventories = async () => {
            const token = localStorage.getItem('token');
            let userId = null;

            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    userId = decodedToken.userId; // Assurez-vous que 'userId' correspond à la clé dans votre token
                } catch (error) {
                    console.error('Error decoding token:', error);
                    setError('Invalid token.');
                    return;
                }
            }

            if (!userId) {
                setError('User ID not found.');
                return;
            }

            try {
                const response = await axios.get('https://drimo.alwaysdata.net/api/inventories', {
                    params: { user_id: userId },
                });
                console.log('Fetched inventories:', response.data);
                setInventories(response.data);
            } catch (err) {
                console.error('Error fetching inventories:', err);
                setError('Failed to fetch inventories.');
            }
        };

        fetchInventories();
    }, []);

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`https://drimo.alwaysdata.net/api/inventories/${id}`);
            console.log('Inventory deleted:', response.data);
            setInventories(prev => prev.filter(inventory => inventory._id !== id));
        } catch (error) {
            console.error('Error deleting inventory:', error);
            setError('Failed to delete inventory.');
        }
    };

    const openModal = (text) => {
        setModalText(text);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (error) {
        return <div className="notification is-danger">{error}</div>;
    }

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block"}}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns pt-2">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-9">
                    <div className="container is-fluid is-flex is-justify-content-end mb-4">
                        <RedirectButton to="/inventory/create" label="Ajouter un produit" className="is-success has-text-link m-1" />
                    </div>
                    {inventories.length > 0 ? (
                        <ul>
                            {inventories.map((inventory) => (
                                <li key={inventory._id} className="m-1">
                                    <div className="columns has-background-info rounded-corners m-2">
                                        <div className="column is-4 my-3">
                                            <h2 className="has-text-link has-text-weight-semibold ml-3 is-size-5">
                                                {inventory.name}
                                                <span
                                                    className="icon ml-1 is-clickable"
                                                    onClick={() => openModal(`Indique le nom du produit.`)}>
                                                    <i className="fa-solid fa-circle-info has-text-success"></i>
                                                </span>
                                            </h2>
                                        </div>
                                        <div className="column is-2 my-3">
                                            <p className="has-text-link has-text-weight-semibold is-size-5">
                                                {inventory.quantity}
                                                <span
                                                    className="icon ml-1 is-clickable"
                                                    onClick={() => openModal(`Indique la quantité correspond au produit.`)}>
                                                    <i className="fa-solid fa-circle-info has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-1 my-3">
                                            <p className="has-text-link has-text-weight-semibold is-size-5">
                                                <span className="icon">
                                                    <i className={`has-text-success ${inventory.reminder ? "fa-solid fa-check" : "fa-solid fa-xmark"}`}></i>
                                                </span>
                                                <span
                                                    className="icon is-clickable"
                                                    onClick={() => openModal(`Le rappel de stock minimum est ${inventory.reminder ? `activé pour ce produit. Votre rappel s'effectura quand le stock attendra ${inventory.minimalQuantity}.` : "désactivé pour ce produit."}`)}>
                                                    <i className="fa-solid fa-circle-info has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-1 my-3">
                                            <p className="has-text-link has-text-weight-semibold is-size-5">
                                                <span className="icon">
                                                    <i className={`has-text-success ${inventory.sell ? "fa-solid fa-check" : "fa-solid fa-xmark"}`}></i>
                                                </span>
                                                <span
                                                    className="icon is-clickable"
                                                    onClick={() => openModal(`Ce produit ${inventory.sell ? `en vente. Le prix de ce produit est de ${inventory.price} €.` : "n'est pas en vente."}`)}>
                                                    <i className="fa-solid fa-circle-info has-text-success"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="column is-1"></div>
                                        <div className="column ml-6 m-1">
                                            <RedirectButton to={`/inventory/update/${inventory._id}`} label={<i className="fa-solid fa-pen"></i>} className="has-background-link has-text-success m-1" />
                                            <DeleteButton id={inventory._id} onDelete={() => handleDelete(inventory._id)} label={<i className="fa-solid fa-trash"></i>} className="has-background-success has-text-link my-1 ml-1" redirectPath="/inventory" />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="has-text-link has-text-weight-semibold is-size-5">
                            Aucun produit trouvé.
                        </p>
                    )}
                    
                    {isModalOpen && (
                        <div className="modal is-active">
                            <div className="modal-background" onClick={closeModal}></div>
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title">Informations</p>
                                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                                </header>
                                <section className="modal-card-body">
                                    <p>{modalText}</p>
                                </section>
                                <footer className="modal-card-foot">
                                    <button className="button is-primary has-text-link" onClick={closeModal}>Fermer</button>
                                </footer>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}