import React, { useState, useEffect } from "react";
import { useStripeConnect } from "../hooks/useStripeConnect";
import {
  ConnectNotificationBanner,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { jwtDecode } from 'jwt-decode';

export default function NotificationBanner() {
  const [connectedAccountId, setConnectedAccountId] = useState(jwtDecode(localStorage.getItem('token')).stripe.acc_id);
  console.log(connectedAccountId);
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  return (
    <div className="container is-max-desktop">
      {connectedAccountId && stripeConnectInstance ? (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectNotificationBanner />
        </ConnectComponentsProvider>
      ) : (
        <p style={{display: "none"}}></p>
      )}
    </div>
  );
}