import React from 'react';
import 'bulma/css/bulma.min.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>DRIMO</strong> by <a href="https://wecyde.com">Wecyde</a>.
        </p>
      </div>
    </footer>
  );
}