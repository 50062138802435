import React from 'react';
import { InlineWidget } from 'react-calendly';

export default function AuthCalendly () {
  return (
    <div className="calendly-container">
      <InlineWidget url="https://calendly.com/drimo-support/60min" />
    </div>
  );
}
/* token = eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzIyNjA0MjMyLCJqdGkiOiJmYTczYzlhMi1mYWVkLTRmZjItODdhZi1mYjllM2M3OGZlNWYiLCJ1c2VyX3V1aWQiOiI0MDY5NWIwNS00ZDJlLTRmYWMtYThkNC03Y2NmODE5ODdiZTMifQ.ceJJbK7LUNxNQA1d5iBu7oUouaTt51x1BltTb-3Gfj2cpN4E56bjYvsy4Pso-LWcO71W20mEmo6-eW0WevnFgw */