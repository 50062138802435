import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import RedirectButton from '../components/RedirectButton';

const typeOptions = [
  { value: "", label: "Sélectionnez votre type d'enseigne" },
  { value: "Coiffure", label: "Coiffure" },
  { value: "Visage", label: "Visage" },
  { value: "Mains_et_pieds", label: "Mains et pieds" },
  { value: "Epilation", label: "Épilation" },
  { value: "Corps", label: "Corps" },
  { value: "Massage", label: "Massage" }
];

const prestationOptions = {
  Coiffure: ["Coupe Femme", "Coupe Homme", "Brushing", "Coloration", "Mèches", "Lissages", "Barbier"],
  Visage: ["Soin", "Extension de cils", "Rehaussement de cils", "Épilation sourcils à la cire", "Épilation sourcils au fil", "Maquillage"],
  Mains_et_pieds: ["Manucure semi-permanente", "Beauté des pieds semi-permanente", "Manucure", "Beauté des pieds", "Pose de faux ongles", "Ongles en gel"],
  Epilation: ["Épilation maillot intégral", "Épilation des aisselles et des bras", "Épilation à la cire", "Épilation au fil", "Épilation homme", "Épilation à la lumière pulsée"],
  Corps: ["Bronzage en cabine", "Hammam et Sauna", "Gommage", "Douche autobronzante et bronzage sans UV", "Soins minceur", "Cryothérapie"],
  Massage: ["Massage en duo", "Massage californien", "Massage aux huiles essentielles", "Massage Thai", "Massage Tui na", "Drainage lymphatique"]
};

export default function CreateBrand() {
  const token = localStorage.getItem('token');
  let decodedToken;

  try {
    decodedToken = jwtDecode(token);
  } catch (error) {
    console.error('Token decoding failed', error);
    decodedToken = null;
  }

  const userid = decodedToken ? decodedToken.userId : null;
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    address: {
      street: '',
      city: '',
      zip: '',
      country: ''
    },
    type: '',
    photo: [],
    description: '',
    phone: '',
    prestations: [],
    links: [],
    dateTime: new Date(),
    user_id: userid,
    openDate: [
      { day: 'Lundi', open: '', close: '', isClosed: false },
      { day: 'Mardi', open: '', close: '', isClosed: false },
      { day: 'Mercredi', open: '', close: '', isClosed: false },
      { day: 'Jeudi', open: '', close: '', isClosed: false },
      { day: 'Vendredi', open: '', close: '', isClosed: false },
      { day: 'Samedi', open: '', close: '', isClosed: false },
      { day: 'Dimanche', open: '', close: '', isClosed: false },
    ],
  });

  const [message, setMessage] = useState(null); // Pour afficher le message de succès ou d'erreur

  // Fetch existing brands for the user
  useEffect(() => {
    async function fetchBrands() {
      try {
        const response = await axios.get(`https://drimo.alwaysdata.net/api/brands?user_id=${userid}`);
        setBrands(response.data);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    }
    if (userid) {
      fetchBrands();
    }
  }, [userid]);

  // Check subscription conditions
  const isLimitedBySubscription = (decodedToken?.stripe.sub_type === "Indépendant" && brands.length === 1) ||
    (decodedToken?.stripe.sub_type === "Premium" && brands.length === 1);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [addressField]: value
        }
      }));
    } else if (name === 'photo') {
      setFormData((prevData) => ({
        ...prevData,
        photo: [...prevData.photo.slice(0, -1), ...files]
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleDayChange = (index, key, value) => {
    const updatedDays = [...formData.openDate];
    updatedDays[index][key] = value;

    // Assurez-vous que la valeur d'heure est bien formatée (HH:MM)
    if (key === 'open' || key === 'close') {
      const [hour, minute] = value.split(':');
      if (hour && minute) {
        updatedDays[index][key] = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
      }
    }

    setFormData({ ...formData, openDate: updatedDays });
  };

  const handlePrestationChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPrestations = [...formData.prestations];
    if (value === "Ajouter moi-même") {
      updatedPrestations[index] = {
        ...updatedPrestations[index],
        [name]: value,
        custom: true
      };
    } else {
      updatedPrestations[index] = {
        ...updatedPrestations[index],
        [name]: value,
        custom: false
      };
    }
    setFormData((prevData) => ({
      ...prevData,
      prestations: updatedPrestations
    }));
  };

  const handleCustomPrestationChange = (e, index) => {
    const { value } = e.target;
    const updatedPrestations = [...formData.prestations];
    updatedPrestations[index] = {
      ...updatedPrestations[index],
      name: value,
      custom: true
    };
    setFormData((prevData) => ({
      ...prevData,
      prestations: updatedPrestations
    }));
  };

  const handlePrestationPriceChange = (e, index) => {
    const { value } = e.target;
    const priceValue = parseFloat(value);

    if (priceValue > 100000) {
      alert('Le prix ne peut pas dépasser 100000 €.');
      return;
    }

    const updatedPrestations = [...formData.prestations];
    updatedPrestations[index] = {
      ...updatedPrestations[index],
      price: priceValue
    };
    setFormData((prevData) => ({
      ...prevData,
      prestations: updatedPrestations
    }));
  };

  const addPrestation = () => {
    setFormData((prevData) => ({
      ...prevData,
      prestations: [...prevData.prestations, { name: "", price: "", custom: false }]
    }));
  };

  const removePrestation = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      prestations: prevData.prestations.filter((_, i) => i !== index)
    }));
  };

  const handleLinkChange = (e, index) => {
    const { name, value } = e.target;
    const updatedLinks = [...formData.links];
    updatedLinks[index] = {
      ...updatedLinks[index],
      [name]: value
    };
    setFormData((prevData) => ({
      ...prevData,
      links: updatedLinks
    }));
  };

  const addLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      links: [...prevData.links, { name: "", url: "" }]
    }));
  };

  const removeLink = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      links: prevData.links.filter((_, i) => i !== index)
    }));
  };

  const addPhotoField = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: [...prevData.photo, '']
    }));
  };

  const removePhoto = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      photo: prevData.photo.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation pour vérifier que tous les champs obligatoires sont remplis
    if (!formData.name || !formData.address.street || !formData.address.city || !formData.address.zip || !formData.address.country || !formData.type || formData.photo.length === 0 || !formData.description || !formData.phone) {
      alert('Veuillez remplir tous les champs obligatoires et télécharger au moins une photo.');
      return;
    }

    // Validation pour vérifier que toutes les prestations ont un nom et un prix
    for (let prestation of formData.prestations) {
      if (!prestation.name || !prestation.price) {
        alert('Veuillez remplir tous les champs de prestations avec un nom et un prix.');
        return;
      }
    }

    // Validation pour vérifier que tous les liens ont un nom et une URL
    for (let link of formData.links) {
      if (!link.name || !link.url) {
        alert('Veuillez remplir tous les champs de liens avec un nom et une URL.');
        return;
      }
    }

    // Validation supplémentaire pour vérifier que le nom a au moins 5 caractères
    if (formData.name.length < 5) {
      alert('Le nom de l\'enseigne doit contenir au moins 5 caractères.');
      return;
    }

    // Check that close time is not earlier than open time for all days
    for (let day of formData.openDate) {
      if (!day.isClosed && day.open && day.close) {
        const [openHour, openMinute] = day.open.split(':').map(Number);
        const [closeHour, closeMinute] = day.close.split(':').map(Number);

        if (closeHour < openHour || (closeHour === openHour && closeMinute <= openMinute)) {
          alert(`L'heure de fermeture pour ${day.day} doit être supérieure à l'heure d'ouverture.`);
          return;
        }
      }
    }

    const dataToSubmit = new FormData();
    dataToSubmit.append('name', formData.name);
    dataToSubmit.append('address[street]', formData.address.street);
    dataToSubmit.append('address[city]', formData.address.city);
    dataToSubmit.append('address[zip]', formData.address.zip);
    dataToSubmit.append('address[country]', formData.address.country);
    dataToSubmit.append('type', formData.type);
    dataToSubmit.append('description', formData.description);
    dataToSubmit.append('phone', formData.phone);
    dataToSubmit.append('dateTime', formData.dateTime.toISOString());
    dataToSubmit.append('user_id', formData.user_id);

    // Convertir openDate en JSON et l'ajouter à FormData
    dataToSubmit.append('openDate', JSON.stringify(formData.openDate));

    formData.photo.forEach((file) => {
      if (file instanceof File) {
        dataToSubmit.append('photo', file);
      }
    });

    formData.prestations.forEach((prestation, index) => {
      dataToSubmit.append(`prestations[${index}][name]`, prestation.name);
      dataToSubmit.append(`prestations[${index}][price]`, prestation.price);
    });

    formData.links.forEach((link, index) => {
      dataToSubmit.append(`links[${index}][name]`, link.name);
      dataToSubmit.append(`links[${index}][url]`, link.url);
    });

    try {
      const response = await axios.post('https://drimo.alwaysdata.net/api/brand', dataToSubmit, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Brand created successfully:', response.data);
      setMessage({ type: 'success', text: 'Ajout de l\'enseigne réussi ! Redirection en cours...' });
      setTimeout(() => {
        navigate('/brands');
      }, 2000);
    } catch (error) {
      console.error('Error creating brand:', error);
      setMessage({ type: 'error', text: 'Erreur lors de l\'ajout de l\'enseigne. Veuillez réessayer plus tard...' });
    }
  };

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="container is-fluid">
        <Header />
      </div>
      {isLimitedBySubscription ? (
        <div className="has-background-link" style={{ height: "100vh" }}>
          <div className="notification is-danger mx-6 has-text-link">
            L'abonnement que vous avez pris ne vous permet pas d'avoir plusieurs enseignes. Améliorer votre abonnement en cliquant sur le bouton en dessous !
          </div>
          <div className="is-flex is-justify-content-center">
            <RedirectButton to="/sub" label="Améliorer mon abonnement" className="button has-text-link blue mt-3 is-large" />
          </div>
        </div>
      ) : (
        <>
          <div className="columns mb-6 mt-3">
            <div className="column is-3">
              <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                <NavbarLog />
              </div>
            </div>
            <div className="column is-8 ml-6 has-background-info rounded-corners">
              <h3 className="pt-2 pb-6 has-text-link has-text-weight-bold has-text-centered is-size-4">Ajouter une enseigne :</h3>
              <form onSubmit={handleSubmit} className="px-2">
                <div className="field">
                  <label className="label has-text-link">
                    Nom de l'enseigne
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className="input has-background-link has-text-success"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Rue
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className="input has-background-link has-text-success"
                      type="text"
                      name="address.street"
                      value={formData.address.street}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Ville
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className="input has-background-link has-text-success"
                      type="text"
                      name="address.city"
                      value={formData.address.city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Code Postal
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className="input has-background-link has-text-success"
                      type="number"
                      name="address.zip"
                      value={formData.address.zip}
                      onChange={handleChange}
                      required
                      pattern="\d{5}"
                      title="Le code postal doit contenir exactement 5 chiffres"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Pays
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className="input has-background-link has-text-success"
                      type="text"
                      name="address.country"
                      value={formData.address.country}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Type d'enseigne
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <div className="select is-success">
                      <select className="has-background-link has-text-success" name="type" value={formData.type} onChange={handleChange} required>
                        {typeOptions.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Prestations et prix (en €)
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    {formData.prestations.map((prestation, idx) => (
                      <div key={idx} className="field has-addons">
                        <div className="control">
                          <div className="select is-success">
                            <select
                              className="has-background-link has-text-success"
                              name="name"
                              value={prestation.custom ? "Ajouter moi-même" : prestation.name}
                              onChange={(e) => handlePrestationChange(e, idx)}
                              required
                            >
                              <option value="">Sélectionnez une prestation</option>
                              {prestationOptions[formData.type]?.map((prestationOption, index) => (
                                <option key={index} value={prestationOption}>
                                  {prestationOption}
                                </option>
                              ))}
                              <option value="Ajouter moi-même">Ajouter moi-même</option>
                            </select>
                          </div>
                        </div>
                        {prestation.custom && (
                          <div className="control is-success">
                            <input
                              className="input has-background-link has-text-success"
                              type="text"
                              placeholder="Nom de la prestation"
                              value={prestation.name}
                              onChange={(e) => handleCustomPrestationChange(e, idx)}
                              required
                            />
                          </div>
                        )}
                        <div className="control">
                          <input
                            className="input has-background-link has-text-success"
                            type="number"
                            placeholder="Prix"
                            name="price"
                            value={prestation.price}
                            onChange={(e) => handlePrestationPriceChange(e, idx)}
                            required
                          />
                        </div>
                        <div className="control">
                          <button type="button" className="button is-danger" onClick={() => removePrestation(idx)}>
                            <span className="icon text-dark">
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="button is-success has-text-link m-1" onClick={addPrestation}>
                      Ajouter une prestation
                    </button>
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Heures d'ouvetures
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  {formData.openDate.map((day, index) => (
                    <div key={index} className="mb-3">
                      <label className="label">{day.day}</label>
                      <div className="columns">
                        <div className="column">
                          <input
                            type="time"
                            value={day.open}
                            onChange={(e) => handleDayChange(index, 'open', e.target.value)}
                            className="input has-background-link has-text-success"
                            disabled={day.isClosed}
                            required={!day.isClosed}
                          />
                        </div>
                        <div className="column">
                          <input
                            type="time"
                            value={day.close}
                            onChange={(e) => handleDayChange(index, 'close', e.target.value)}
                            className="input has-background-link has-text-success"
                            disabled={day.isClosed}
                            required={!day.isClosed}
                          />
                        </div>
                        <div className="column is-narrow">
                          <label className="checkbox has-text-link">
                            <input
                              type="checkbox"
                              checked={day.isClosed}
                              onChange={(e) => handleDayChange(index, 'isClosed', e.target.checked)}
                            />
                            &nbsp; Closed
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Liens (nom + url)
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    {formData.links.map((link, idx) => (
                      <div key={idx} className="field has-addons">
                        <div className="control">
                          <input
                            className="input has-background-link has-text-success"
                            type="text"
                            placeholder="Nom du lien"
                            name="name"
                            value={link.name}
                            onChange={(e) => handleLinkChange(e, idx)}
                            required
                          />
                        </div>
                        <div className="control">
                          <input
                            className="input has-background-link has-text-success"
                            type="url"
                            placeholder="URL"
                            name="url"
                            value={link.url}
                            onChange={(e) => handleLinkChange(e, idx)}
                            required
                          />
                        </div>
                        <div className="control">
                          <button type="button" className="button is-danger" onClick={() => removeLink(idx)}>
                            <span className="icon text-dark">
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="button is-success has-text-link m-1" onClick={addLink}>
                      Ajouter un lien
                    </button>
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Photos
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  {formData.photo.map((photo, idx) => (
                    <div key={idx} className="file has-name is-success m-1">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          name="photo"
                          accept="image/png"
                          onChange={handleChange}
                          required={idx === 0}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fas fa-upload has-text-link"></i>
                          </span>
                          <span className="file-label has-text-link">Choisissez un fichier…</span>
                        </span>
                        {photo && photo instanceof File && (
                          <span className="file-name has-background-link has-text-success">
                            {photo.name}
                          </span>
                        )}
                      </label>
                      <button type="button" className="button is-danger ml-1" onClick={() => removePhoto(idx)}>
                        <span className="icon text-dark">
                          <i className="fa-solid fa-trash"></i>
                        </span>
                      </button>
                    </div>
                  ))}
                  <button type="button" className="button is-success has-text-link m-1" onClick={addPhotoField}>
                    Ajouter une photo
                  </button>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Description
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea has-background-link has-text-success"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label has-text-link">
                    Téléphone
                    <span className="icon">
                      <i className="fa-solid fa-asterisk has-text-success"></i>
                    </span>
                  </label>
                  <div className="control">
                    <input
                      className="input has-background-link has-text-success"
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      pattern="\d{10}"
                      title="Le téléphone doit contenir exactement 10 chiffres"
                    />
                  </div>
                </div>
                {message && (
                  <div className={`notification ${message.type === 'success' ? 'is-primary has-text-link' : 'is-danger has-text-link'} mx-6`}>
                    {message.text}
                  </div>
                )}
                <div className="control py-3 has-text-centered">
                  <button type="submit" className="button is-success has-text-link">Ajouter</button>
                </div>
              </form>
            </div>
            <div className="column is-1"></div>
          </div>
        </>
      )}
    </div>
  );
}