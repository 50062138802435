import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../components/DropdownButton';
import RedirectButton from '../components/RedirectButton';
import Calendar from '../components/Calendar';
import NavbarLog from '../components/NavbarLog';
import 'bulma/css/bulma.min.css';
import Header from '../components/Header';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import "../style/index.css";
import DeleteButton from '../components/DeleteButton';

export default function Dashboard() {
  const navigate = useNavigate();
  const [waitingReservations, setWaitingReservations] = useState([]);
  const [reservationsTrue, setReservationsTrue] = useState([]);
  const [period, setPeriod] = useState("30 derniers jours");
  const [salesData, setSalesData] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [brand, setBrand] = useState(''); // Initial brand state
  const [allBrand, setAllBrand] = useState([]);
  const [revenues, setRevenues] = useState(0);
  const [reservationsByBrand, setReservationsByBrand] = useState({});

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const user_id = decodedToken.userId;

  // Fetch brands from the API
  const fetchBrands = async () => {
    try {
      const response = await axios.get('https://drimo.alwaysdata.net/api/brands', {
        params: { user_id }
      });

      setAllBrand(response.data);
      if (response.data.length > 0) {
        setBrand(response.data[0]._id); // Set the first brand as default
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des enseignes:', error);
    }
  };

  // Fetch reservations
  const fetchReservations = async () => {
    try {
      const response = await axios.get('https://drimo.alwaysdata.net/api/reservation', {
        params: { user_id }
      });

      console.log('Reservations received:', response.data.reservationsTrue);

      const byBrand = response.data.reservationsTrue.reduce((acc, reservation) => {
        const brandId = reservation.brand_id;
        if (!acc[brandId]) {
          acc[brandId] = [];
        }
        acc[brandId].push(reservation);
        return acc;
      }, {});

      console.log('Reservations grouped by brand:', byBrand);

      setReservationsByBrand(byBrand);
      setWaitingReservations(response.data.waitingReservation);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  // Fetch sales data and revenue
  const fetchData = async () => {
    try {
      if (brand) {
        const revenueResponse = await axios.get('https://drimo.alwaysdata.net/api/revenue', {
          params: { brand_id: brand, period }
        });

        setRevenues(revenueResponse.data.revenue || 0);
        setMessage(revenueResponse.data.message || 'Aucune donnée disponible pour la période sélectionnée');

        const salesResponse = await axios.get('https://drimo.alwaysdata.net/api/top-selling-items', {
          params: { brand_id: brand, period }
        });

        setSalesData(salesResponse.data || []);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des articles les plus vendus:', error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    if (allBrand.length > 0 && !brand) {
      setBrand(allBrand[0]._id); // Définit le brand par défaut avec le premier élément de allBrand
    }
  }, [allBrand]);

  useEffect(() => {
    if (brand) {
      fetchReservations();
      fetchData();
    }
  }, [brand, period]);

  console.log(brand, period);

  // Get the next reservation
  const getNextReservationForBrand = (brandId) => {
    console.log('Getting next reservation for brand:', brandId);
    console.log('Reservations available for this brand:', reservationsByBrand[brandId]);

    if (!reservationsByBrand[brandId] || reservationsByBrand[brandId].length === 0) {
      console.log('No reservations found for this brand.');
      return null;
    }

    const now = new Date();
    console.log('Current date:', now);

    const nextReservation = reservationsByBrand[brandId]
      .map(reservation => {
        const [day, month, year] = reservation.dateTime[0].date.split('/');
        const parsedDate = new Date(`${year}-${month}-${day}`);
        console.log('Parsed reservation date:', parsedDate);
        return {
          ...reservation,
          parsedDate
        };
      })
      .filter(reservation => reservation.parsedDate > now)
      .sort((a, b) => a.parsedDate - b.parsedDate)[0];

    console.log('Next reservation found:', nextReservation);
    return nextReservation;
  };

  const nextReservation = getNextReservationForBrand(brand);

  console.log('Next reservation to display:', nextReservation);

  const formatDateTime = (dateTimeObj) => {
    const { day, hour, minute, date } = dateTimeObj;
    return `${day} ${date}, à ${hour}:${minute}`;
  };

  const handleAcceptReservation = async (id, email, fName, dateTime, brand_id) => {
    try {
      await axios.put(`https://drimo.alwaysdata.net/api/reservation/${id}/accept`, {
        email,
        fName,
        dateTime,
        brand_id
      });
      window.location.reload();  // Ajoute un rechargement de la page après l'acceptation
    } catch (error) {
      console.error('Error updating reservation status:', error);
    }
  };

  const handleDelete = async (id, email, fName, dateTime, prestation) => {
    try {
      await axios.delete(`https://drimo.alwaysdata.net/api/reservation/${id}`, {
        data: { email, fName, dateTime, prestation }
      });
      window.location.reload();  // Ajoute un rechargement de la page après la suppression
    } catch (error) {
      console.error('Error deleting reservation:', error);
      setError('Échec de la suppression de la réservation.');
    }
  };

  const openModal = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent('');
  };

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
      <div className="container is-fluid">
        <Header />
      </div>
      <div className="columns mt-1">
        <div className="column is-3">
          <div className="has-background-info py-2 pr-2 pl-4 navBorder">
            <NavbarLog />
          </div>
        </div>
        <div className="column is-5">
          <div className="columns is-mobile">
            <div className="column is-6">
              <DropdownButton
                label={allBrand.find(b => b._id === brand)?.name || "Sélectionnez une enseigne"}
                fields={allBrand.map(b => b.name)}
                onChange={(selectedBrand) => {
                  const brandObj = allBrand.find(b => b.name === selectedBrand);
                  setBrand(brandObj ? brandObj._id : allBrand[0]._id);
                }}
              />
              <h3 className="py-3 text-dark has-text-weight-bold">Chiffre d'affaires :</h3>
              {revenues === 0 ? (
                <div className="is-flex is-justify-content-center">
                  <div className="has-background-success py-2 rounded-corners">
                    <div className="has-text-link has-text-centered">Vous n'avez pas de chiffre d'affaire pour la période séléctionnée.</div>
                  </div>
                </div>
              ) : (
                <div className="is-flex is-justify-content-center">
                  <div className="has-background-white p-2 rounded-corners border-success">
                    <p className="is-size-1 has-text-success has-text-centered">{revenues}€</p>
                  </div>
                </div>
              )}
            </div>
            <div className="column is-6">
              <DropdownButton
                label={period}
                fields={["30 derniers jours", "Annuel", "Depuis le premier jour"]}
                onChange={setPeriod}
              />

              <h3 className="py-3 text-dark has-text-weight-bold">Articles les plus vendus :</h3>

              {/* {salesData.length > 0 ? (
                <ul className="has-background-info p-2 mr-5 rounded-corners">
                  {salesData.map((item, index) => (
                    <li className="has-text-link level" key={index}>
                      <span className="level-left">
                        <span className="level-item has-text-weight-semibold">
                          {item._id} :
                        </span>
                      </span>
                      <span className="level-right">
                        <span className="level-item">
                          {item.totalQuantity}
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="has-text-success">Aucun article trouvé pour cette période</p>
              )} */}
              <ul className="has-background-info p-2 rounded-corners mr-2"> {/* temporaire / le temps que la vente de produit fonctionne */}
                <li className="has-text-link has-text-centered">Temporairement indisponible</li>
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="level">
              <div className="level-left">
                <h3 className="text-dark has-text-weight-bold level-item">Calendrier du mois :</h3>
              </div>
              <div className="level-right">
                <h4 className="text-dark has-text-weight-medium level-item">
                  Comment ça marche ?
                  <span className="icon is-clickable" onClick={() => openModal(
                    <div className="fixed-grid has-2-cols">
                      <div className="grid">
                        <div className="cell is-col-span-2 is-row-start-1">
                          <p className="has-text-centered">Vous retrouver ci-dessous toutes les réservations de toutes vos enseignes.</p>
                        </div>
                        <div className="cell is-col-span-2 is-row-start-2">
                          <span className="tag is-large text-white" style={{ backgroundColor: "#00FF" }}>Jour d'aujourd'hui</span>
                        </div>
                        <div className="cell is-col-span-2 is-row-start-3">
                          <span className="tag is-large text-dark" style={{ backgroundColor: "#00FF00" }}>Jour composé d'une seule réservation</span>
                        </div>
                        <div className="cell is-col-span-2 is-row-start-4">
                          <span className="tag is-large text-dark" style={{ backgroundColor: "#FFD100" }}>Jour composé de deux réservations</span>
                        </div>
                        <div className="cell is-col-span-2 is-row-start-5">
                          <span className="tag is-large text-white" style={{ backgroundColor: "#FF0000" }}>Jour composé de trois réservations ou plus</span>
                        </div>
                      </div>
                    </div>
                  )}>
                    <i className="fa-solid fa-circle-info"></i>
                  </span>
                </h4>
              </div>
            </div>
            <Calendar />
          </div>
        </div>
        <div className="column is-4">
          <div className="is-flex is-justify-content-end mb-2 mr-3">
            <RedirectButton to="/reservation/create" label="Création d'une réservation" className="is-success has-text-link" />
          </div>
          <h3 className="py-2 text-dark has-text-weight-bold">Prochaine réservation :</h3>
          {nextReservation ? (
            <ul className="mr-1 has-background-info rounded-corners p-4">
              <li className="my-3 has-background-link rounded-corners">
                <div className="fixed-grid has-4-cols">
                  <div className="grid">
                    <div className="cell ml-5 mt-1 is-flex is-justify-content-center has-text-success has-text-weight-semibold">
                      <span className="icon is-clickable" onClick={() => openModal(
                        <>
                          <p><strong>Nom :</strong> {nextReservation.customerLName}</p>
                          <p><strong>Prénom :</strong> {nextReservation.customerFName}</p>
                          <p><strong>Email :</strong> {nextReservation.customerEmail}</p>
                          <p><strong>Téléphone :</strong> {nextReservation.customerPhone}</p>
                        </>
                      )}>
                        <p>Client</p>
                        <i className="fas fa-user ml-1 has-text-success"></i>
                      </span>
                    </div>
                    <div className="cell has-text-success has-text-weight-semibold is-flex is-justify-content-center mt-1">
                      <span className="icon is-clickable" onClick={() => openModal(
                        <>
                          <p><strong>Prestation :</strong> {nextReservation.prestation[0]?.name}</p>
                          <p><strong>Prix :</strong> {nextReservation.prestation[0]?.price}€</p>
                          <p><strong>Commentaire :</strong> {nextReservation.comment}</p>
                          <p><strong>Enseigne :</strong> {nextReservation.brand_name}</p>
                        </>
                      )}>
                        <p>Détails</p>
                        <i className="fa-solid fa-circle-info has-text-success ml-1"></i>
                      </span>
                    </div>
                    <div className="cell is-row-span-2 is-col-span-2 is-col-start-3 is-flex is-justify-content-end m-1 my-4 mr-3">
                      <RedirectButton to={`/reservation/update/${nextReservation._id}`} label={<i className="fa-solid fa-pen"></i>} className="has-text-success has-background-link m-1" />
                      <DeleteButton id={nextReservation._id} onDelete={() => handleDelete(nextReservation._id, nextReservation.customerEmail, nextReservation.customerFName, nextReservation.dateTime, nextReservation.prestation)} label={<i className="fa-solid fa-trash"></i>} className="has-text-link has-background-success my-1 ml-1" />
                    </div>
                    <div className="cell is-row-start-2 is-col-span-2 has-text-success has-text-weight-semibold my-1 is-flex is-justify-content-center">
                      <span className="icon is-clickable" onClick={() => openModal(
                        <p><strong>{formatDateTime(nextReservation.dateTime[0])}</strong></p>
                      )}>
                        <div className="is-flex is-justify-content-center">
                          {nextReservation.dateTime[0].date}
                        </div>
                        <i className="fa-regular fa-calendar-days has-text-success ml-1"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          ) : (
            <div className="notification is-info has-text-centered mr-1 has-text-link">
              Vous n'avez pas de réservation pour le moment.
            </div>
          )}
          <h3 className="py-2 text-dark has-text-weight-bold">Réservations en attente :</h3>
          {waitingReservations.length === 0 ? (
            <div className="notification is-info has-text-centered mr-1 has-text-link">
              Vous n'avez pas de réservation en attente pour le moment.
            </div>
          ) : (
            <ul className="mr-1 has-background-info rounded-corners p-4" style={{ height: "450px", overflow: "hidden", overflowY: "scroll" }}>
              {waitingReservations.map((reservation, index) => (
                <li key={index} className="my-3 has-background-link rounded-corners">
                  <div className="fixed-grid has-4-cols">
                    <div className="grid">
                      <div className="cell ml-5 mt-1 is-flex is-justify-content-center has-text-success has-text-weight-semibold">
                        <span className="icon is-clickable" onClick={() => openModal(
                          <>
                            <p><strong>Nom :</strong> {reservation.customerLName}</p>
                            <p><strong>Prénom :</strong> {reservation.customerFName}</p>
                            <p><strong>Email :</strong> {reservation.customerEmail}</p>
                            <p><strong>Téléphone :</strong> {reservation.customerPhone}</p>
                          </>
                        )}>
                          Client
                          <i className="fas fa-user ml-1 has-text-success"></i>
                        </span>
                      </div>
                      <div className="cell has-text-success has-text-weight-semibold is-flex is-justify-content-center mt-1">
                        <span className="icon is-clickable" onClick={() => openModal(
                          <>
                            <p><strong>Prestation :</strong> {reservation.prestation[0]?.name}</p>
                            <p><strong>Prix :</strong> {reservation.prestation[0]?.price}€</p>
                            <p><strong>Commentaire :</strong> {reservation.comment}</p>
                            <p><strong>Enseigne :</strong> {reservation.brand_name}</p>
                          </>
                        )}>
                          Détails
                          <i className="fa-solid fa-circle-info has-text-success ml-1"></i>
                        </span>
                      </div>
                      <div className="cell is-row-span-2 is-col-span-2 is-col-start-3 is-flex is-justify-content-end m-1 my-4">
                        <button onClick={() => handleAcceptReservation(reservation._id, reservation.customerEmail, reservation.customerFName, reservation.dateTime, reservation.brand_id)} className="button has-text-success has-background-link m-1">
                          <i className="fa-solid fa-check"></i>
                        </button>
                        <RedirectButton to={`/reservation/refuse/${reservation._id}`} label={<i className="fa-solid fa-xmark"></i>} className="has-text-link has-background-success m-1" />
                      </div>
                      <div className="cell is-row-start-2 is-col-span-2 has-text-success has-text-weight-semibold my-1 is-flex is-justify-content-center">
                        <span className="icon is-clickable" onClick={() => openModal(
                          <p><strong>{formatDateTime(reservation.dateTime[0])}</strong></p>
                        )}>
                          {reservation.dateTime[0].date}
                          <i className="fa-regular fa-calendar-days has-text-success ml-1"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {modalContent && (
            <div className="modal is-active">
              <div className="modal-background" onClick={closeModal}></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Informations</p>
                  <button className="delete" aria-label="close" onClick={closeModal}></button>
                </header>
                <section className="modal-card-body">
                  {modalContent}
                </section>
                <footer className="modal-card-foot">
                  <button className="button is-primary" onClick={closeModal}>Fermer</button>
                </footer>
              </div>
            </div>
          )}
        </div>
      </div >
    </div >
  );
}