import React, { useState, useEffect } from 'react';

export default function DropdownButton({ label, fields, onChange }) {
  const [isActive, setIsActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(label); // Initialise avec le label reçu

  useEffect(() => {
    setSelectedItem(label); // Met à jour selectedItem chaque fois que label change
  }, [label]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsActive(false);
    if (onChange) {
      onChange(item);
    }
  };

  return (
    <div className={`dropdown ${isActive ? 'is-active' : ''}`}>
      <div className="dropdown-trigger">
        <button className="button has-background-success has-text-link" onClick={() => setIsActive(!isActive)} aria-haspopup="true" aria-controls="dropdown-menu">
          <span>{selectedItem}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content has-background-success has-text-link">
          {fields.map((field, index) => (
            <a
              href="#"
              key={index}
              className={`dropdown-item has-background-success has-text-link ${selectedItem === field ? 'is-active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                handleItemClick(field);
              }}
            >
              {field}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}