import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/connect_register'); // Rediriger vers le tableau de bord si l'utilisateur a déjà une session
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null); // Réinitialiser le message

    try {
      const response = await axios.post('https://drimo.alwaysdata.net/api/login', { email, password });
      if (response.data.status === 'success') {
        localStorage.setItem('token', response.data.token);
        setMessage({ type: 'success', text: 'Connexion réussie!' });
     
          navigate('/connect_register');
   
      } else {
        setMessage({ type: 'error', text: response.data.message });
      }
    } catch (error) {
      setMessage({ type: 'error', text: 'Une erreur est survenue. Veuillez réessayer.' });
    }
  };

  return (
    <div id="root" className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="container is-fluid">
        <Header />
        {message && (
          <div className={`notification ${message.type === 'success' ? 'is-success' : 'is-danger'}`}>
            {message.text}
          </div>
        )}
        <div className="columns py-6">
          <div className="column is-3"></div>
          <div className="column is-6 has-background-info rounded-corners">
            <h2 className="title is-flex is-justify-content-center has-text-link pt-2">Connexion</h2>
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label has-text-link">Email</label>
                <div className="control">
                  <input
                    className="input has-text-success has-background-link"
                    type="email"
                    placeholder="Entrez votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-link">Mot de passe</label>
                <div className="control">
                  <input
                    className="input has-text-success has-background-link"
                    type="password"
                    placeholder="Entrez votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="control is-flex is-justify-content-center py-3">
                  <button className="button is-success has-text-link" type="submit">
                    Connexion
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="column is-3"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
