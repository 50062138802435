
import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import 'bulma/css/bulma.min.css';
export default function PricingPage() {
  // Paste the stripe-pricing-table snippet in your React component
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setUserData(decoded);
    }
  }, []);
  if (!userData) {
    return <div>Loading...</div>;
  }

 
  return (<>
    <section className="hero is-primary">
      <div className="hero-body">
        <p className="title has-text-link">
          Choisissez votre abonnement
        </p>
      </div>
    </section>

    <section className="section has-background-link">
      <div className="content">
        <h2 className="has-text-success">Bienvenue {userData.fName} {userData.lName}</h2>
      </div>
    </section>
    <stripe-pricing-table
      pricing-table-id="prctbl_1PiZt6I9EQYdpTRzX2jyJaZn"
      publishable-key="pk_test_R5tPjZTcM4qtaqqHODts4vaL00rSCv85jU"
      customer-email={userData.email}
      client-reference-id={userData.stripe.cu_id}
    >
    </stripe-pricing-table></>
  );
}

