import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import NavbarLog from '../components/NavbarLog';
import DeleteButton from '../components/DeleteButton';
import { jwtDecode } from 'jwt-decode';

export default function PDFReservation() {
    const [pdfs, setPdfs] = useState([]);

    useEffect(() => {
        async function fetchPdfs() {
            try {
                const token = localStorage.getItem('token');
                const decodedToken = jwtDecode(token);
                const user_id = decodedToken.userId;
                
                const response = await axios.get('https://drimo.alwaysdata.net/api/pdf/reservation', {
                    params: { user_id }
                });
                setPdfs(response.data);
            } catch (error) {
                console.error('Error fetching PDFs:', error);
            }
        }

        fetchPdfs();
    }, []);

    const extractDateInfo = (pdf) => {
        if (pdf.containsAll) {
            return "Toutes les réservations";
        }

        const firstReservationDate = new Date(pdf.startDate.split('/').reverse().join('-'));
        const options = { year: 'numeric', month: 'long' };
        const monthYear = firstReservationDate.toLocaleDateString('fr-FR', options);

        return monthYear;
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://drimo.alwaysdata.net/api/pdf/reservation/${id}`);
            console.log('PDF deleted');

            // Actualiser la liste des PDFs après suppression
            setPdfs(pdfs.filter(pdf => pdf._id !== id));
        } catch (error) {
            console.error('Error deleting PDF:', error);
            alert('Échec de la suppression du PDF');
        }
    };

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
            <div className="container is-fluid">
                <Header />
            </div>
            <div className="columns mb-6 mt-3">
                <div className="column is-3">
                    <div className="has-background-info py-2 pr-2 pl-4 navBorder">
                        <NavbarLog />
                    </div>
                </div>
                <div className="column is-8 ml-6">
                    <h3 className="pt-2 pb-6 text-dark has-text-weight-bold has-text-centered is-size-4">Mes PDFs de réservations</h3>
                    {pdfs.length === 0 ? (
                        <div className="notification is-info has-text-centered has-text-link">
                            Aucun PDF de réservation n'a été trouvé.
                        </div>
                    ) : (
                        <ul>
                            {pdfs.map((pdf, index) => (
                                <li key={index} className="mb-4 has-background-info rounded-corners">
                                    <div className="columns is-vcentered">
                                        <div className="column is-1"></div>
                                        <div className="column is-4">
                                            <p className="has-text-link has-text-weight-semibold">
                                                <span>{capitalizeFirstLetter(extractDateInfo(pdf))}</span>
                                            </p>
                                        </div>
                                        <div className="column is-4 has-text-right">
                                            <a
                                                href={`https://drimo.alwaysdata.net/${pdf.file}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="button is-success has-text-link is-clickable"
                                                download={pdf.file}
                                            >
                                                Télécharger
                                            </a>
                                        </div>
                                        <div className="column">
                                            <DeleteButton id={pdf._id} onDelete={() => handleDelete(pdf._id)} label={<i className="fa-solid fa-trash"></i>} className="has-text-link has-background-success my-1 ml-1" />
                                        </div>
                                        <div className="column is-1"></div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="column is-1"></div>
            </div>
        </div>
    );
}