import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CancelReservation = () => {
    const { id } = useParams();  // Récupère l'ID de la réservation depuis l'URL
    const [canceledData, setCanceledData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false); // Pour savoir si l'utilisateur a confirmé

    const handleCancelReservation = async () => {
        setLoading(true);
        setError(null);
        setCanceledData(null);

        try {
            const response = await axios.put(`https://drimo.alwaysdata.net/api/cancel/${id}`);
            setCanceledData(response.data.canceled);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setError('Réservation non trouvée ou non annulée', err.response);
            } else {
                setError('Erreur lors de l\'annulation de la réservation');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = () => {
        setConfirmed(true);
        handleCancelReservation();
    };

    return (
        <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
            <div className="container">
                {!confirmed ? (
                    <div className="container">
                        <h1 className="title is-4 has-text-centered has-text-success">Confirmation de l'annulation</h1>
                        <p className="content has-text-success">Vous êtes sur le point d'annuler votre réservation. Cela peut entraîner un remboursement partiel ou complet selon le moment de l'annulation. Êtes-vous sûr de vouloir continuer ?</p>
                        <button className="button has-background-danger has-text-link" onClick={handleConfirm} disabled={loading}>
                            {loading ? 'Annulation en cours...' : 'Confirmer l\'annulation'}
                        </button>
                    </div>
                ) : (
                    <div className="container">
                        <h1 className="title is-4 has-text-success has-text-centered">Annuler la Réservation</h1>
                        {loading && <p className="content has-text-success">Annulation en cours...</p>}
                        {error && <p className="notification has-background-danger has-text-link">{error}</p>}

                        {canceledData && (
                            <div className="content">
                                <h2 className="subtitle is-5 has-text-centered">Détails de l'annulation</h2>
                                <p className="has-text-success"><strong className="has-text-success">Prénom du client :</strong> {canceledData.customerFName}</p>
                                <p className="has-text-success"><strong className="has-text-success">Date :</strong> {canceledData.date}</p>
                                <p className="has-text-success"><strong className="has-text-success">Heure :</strong> {canceledData.hour}:{canceledData.min}</p>
                                <p className="has-text-success"><strong className="has-text-success">Remboursement :</strong> {canceledData.refund === 'complete' ? 'Un remboursement complet a été réalisé' : 'Un remboursement partiel a été réalisé'}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CancelReservation;
