import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export default function ProtectedRoute({ Component }) {
  const [hasAccess, setHasAccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        const allowedSubTypes = ['Indépendant', 'Premium', 'Entreprise'];

        if (
          decodedToken?.stripe?.sub_type &&
          allowedSubTypes.includes(decodedToken.stripe.sub_type)
        ) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }
      } catch (error) {
        setHasAccess(false);
      }
    } else {
      setHasAccess(false);
    }
  }, []);

  useEffect(() => {
    if (hasAccess === false) {
      return (
        <div className="has-background-link p-1 pt-2">
          <h2 className="has-text-success has-text-centered is-size-3 has-text-weight-bold">Accès refusé</h2>
          <p className="has-text-success has-text-centered">
            Vous n'avez pas accès à cette partie du site. Vous avez besoin d'un abonnement pour accéder à ce contenu.
          </p>
        </div>
      );
    }
  }, [hasAccess, navigate]);

  if (hasAccess === null) {
    return <div>Chargement...</div>;
  }

  return hasAccess ? <Component /> : <div className="has-background-link p-1 pt-2">
    <h2 className="has-text-success has-text-centered is-size-3 has-text-weight-bold">Accès refusé</h2>
    <p className="has-text-success has-text-centered">
      Vous n'avez pas accès à cette partie du site. Vous avez besoin d'un abonnement pour accéder à ce contenu.
    </p>
  </div>;
};