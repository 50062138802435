import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();

    // Rediriger après 3 secondes
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);

    // Nettoyer le timer lors du démontage du composant
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="container is-fluid" style={{ height: '100vh' }}>
        <span className="is-flex is-justify-content-center">
          <img className="trente-cinq-pourcent my-3" src="/logout.png" alt="Au revoir" />
        </span>
        <span className="is-flex is-justify-content-center">
          <h1 className="title has-text-centered pt-3">Nous sommes en train de vous déconnecter, merci de patienter...</h1>
        </span>
      </div>
    </div>
  );
}