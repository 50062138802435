import React, { useState, useEffect } from "react";
import { useStripeConnect } from "../hooks/useStripeConnect";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import Header from '../components/Header';
import NavbarLog from "../components/NavbarLog";
import { jwtDecode } from 'jwt-decode';

export default function Payments() {
  const [connectedAccountId, setConnectedAccountId] = useState(jwtDecode(localStorage.getItem('token')).stripe.acc_id);
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  console.log(connectedAccountId);

  useEffect(() => {
    if (!connectedAccountId) {
      // Handle the case where the connected account ID is not present
      console.log('No connected account ID found in localStorage.');
    }
  }, [connectedAccountId]);

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
      <div className="container is-fluid">
        <Header />
        <h3 className="pt-2 pb-2 text-dark has-text-weight-bold has-text-centered is-size-4 mb-2">Réclamations :</h3>
          <div className="container is-max-desktop mb-5">
          <div className="notification has-background-danger has-text-white">
            <strong>Veuillez noter que vous trouverez les réclamations ou litiges formulés par vos clients. Pour contester un litige, il est essentiel de démontrer que le service payé a bien été rendu et que le client se trompe ou fait une fausse déclaration. Nous vous encourageons à collecter toute preuve nécessaire pour appuyer votre position. Vous êtes libre de choisir les méthodes qui vous semblent les plus appropriées, que ce soit en demandant au client de signer un document, de prendre une photo de sa carte bancaire ou d'identité, ou toute autre preuve tangible.

Afin de vous soutenir dans cette démarche, sachez que pour chaque commande passée, vous recevrez un duplicata informant le client de la réservation effectuée. Vous pouvez utiliser une capture d'écran de cet e-mail comme preuve de bonne foi, en précisant que le client avait été informé et qu'il pouvait annuler la réservation à tout moment, conformément aux clauses de contrat chez Drimo.

Nous vous faisons pleinement confiance pour gérer ces situations avec professionnalisme et imagination.</strong>
          </div>
          </div>
      </div>
      <div className="columns">
        <div className="column is-3 mr-5">
          <div className="has-background-info py-2 pr-2 pl-4 navBorder">
            <NavbarLog />
          </div>
        </div>
        
        <div className="column is-7 ml-5">
          <div className="container mt-2">
          
            <div className="container">
              {connectedAccountId && stripeConnectInstance ? (
                <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                  <ConnectPayments />
                </ConnectComponentsProvider>
              ) : (
                <p>Chargement des paiements Stripe...</p>
              )}
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}
