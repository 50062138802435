import React, { useState, useEffect } from "react";
import { useStripeConnect } from "../hooks/useStripeConnect";
import {
  ConnectAccountManagement,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import Header from '../components/Header';
import NavbarLog from "../components/NavbarLog";
import { jwtDecode } from 'jwt-decode';

export default function AccountManagement() {
  const [connectedAccountId, setConnectedAccountId] = useState(jwtDecode(localStorage.getItem('token')).stripe.acc_id);
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  console.log(connectedAccountId);

  useEffect(() => {
    if (!connectedAccountId) {
      // Handle the case where the connected account ID is not present
      console.log('No connected account ID found in localStorage.');
    }
  }, [connectedAccountId]);

  return (
    <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
      <div className="container is-fluid">
        <Header />
        <h3 className="pt-2 pb-2 text-dark has-text-weight-bold has-text-centered is-size-4 mb-2">Gestion des informations :</h3>
          <div className="container is-max-desktop mb-5">
          <div className="notification has-background-danger has-text-white">
           <strong>Veuillez noter que toutes vos informations professionnelles et personnelles, y compris votre site Web, secteur d'activité, et les détails figurant sur les relevés bancaires des clients, sont visibles et modifiables sur cette page. Il est important de vous assurer que ces données sont exactes et à jour. Vous pouvez également gérer les informations de votre compte bancaire et d'authentification de paiement. 
              Si nécessaire, vous avez la possibilité de modifier ou d'ajouter de nouvelles informations pour garantir une gestion optimale de vos transactions.</strong>
          </div>
          </div>
      </div>
      <div className="columns">
        <div className="column is-3 mr-5">
          <div className="has-background-info py-2 pr-2 pl-4 navBorder">
            <NavbarLog />
          </div>
        </div>
       
        <div className="column is-7 ml-5">
          <div className="container mt-2">
          
            <div className="container">
              {connectedAccountId && stripeConnectInstance ? (
                <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                  <ConnectAccountManagement
                    // Optional props can be added here if needed
                    // collectionOptions={{
                    //   fields: 'eventually_due',
                    //   futureRequirements: 'include',
                    // }}
                  />
                </ConnectComponentsProvider>
              ) : (
                <p>Chargement de la gestion de compte Stripe...</p>
              )}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
