import React, { useState, useEffect } from "react";
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { useStripeConnect } from "../hooks/useStripeConnect";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
export default function Connectostripe() {
  const navigate = useNavigate();
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState(localStorage.getItem('connectedAccountId'));
  const [accountIncomplete, setAccountIncomplete] = useState(false);
  const [accountCompletion, setAccountCompletion] = useState(false);
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
 // Effect to check and handle account setup on component mount
 useEffect(() => {
  const handleAccountSetup = async () => {
    if (!connectedAccountId && !accountCreatePending) {
      await handleSignUp();
    }
  };

  handleAccountSetup();
}, [connectedAccountId, accountCreatePending]);

  useEffect(() => {
    if (connectedAccountId) {
      // Check the account status
      fetch(`https://drimo.alwaysdata.net/api/account_status/${connectedAccountId}`)
        .then(response => response.json())
        .then(data => {
          setAccountIncomplete(data.accountIncomplete);
        });
    }
  }, [connectedAccountId]);

  useEffect(() => {
    if (onboardingExited) {
      // Check the account status
      fetch(`https://drimo.alwaysdata.net/api/account_completion/${connectedAccountId}`)
        .then(response => response.json())
        .then(data => {
          console.log(data.accountCompletion);
          if (data.accountCompletion) {
            navigate('/dashboard');
          }
        });
    }
  }, [onboardingExited]);

  const handleSignUp = async () => {
    setAccountCreatePending(true);
    setError(false);

    try {
      const decodedToken = jwtDecode(localStorage.getItem('token'));

      if(decodedToken.stripe.acc_id === null) {
// Extraire les propriétés nécessaires
const { fName, lName, email } = decodedToken;
      const response = await axios.post('https://drimo.alwaysdata.net/api/account', { fName, lName, email});
      

      const { account, error } = response.data;
        
      if (account) {
        // Assumer que connectedAccountId est défini et correct
      const token = jwtDecode(localStorage.getItem('token'));
      const email = token.email;
      const connectedAccountId = account;
        const refreshTokenResponse = await axios.post('https://drimo.alwaysdata.net/api/refresh-token-for-acct', {
          email: email,
          acct: connectedAccountId
        });
        const refreshTokenJson = refreshTokenResponse.data;
        if (refreshTokenJson.status === 'success') {
        const newToken = refreshTokenJson.token;
        console.log(jwtDecode(newToken));
        localStorage.setItem('token', newToken);
        console.log('New token saved in localStorage');
        navigate('/connect_register');
        window.location.href = "https://www.clahess.com/connect_register";
      } else {
        console.error('Failed to refresh token:', refreshTokenJson.message);
      }
        localStorage.setItem('connectedAccountId', account);
        
      }
      setAccountCreatePending(false);
      if (error) {
        setError(true);
      }

    } else {
      localStorage.setItem('connectedAccountId', decodedToken.stripe.acc_id);
      setAccountCreatePending(false);
      window.location.href = "https://www.clahess.com/connect_register";
    }   
    } catch (err) {
      setError(true);
      setAccountCreatePending(false);
    }
  };

  return (<>
  <div className="has-background-link" style={{ minHeight: "100vh", display: "block" }}>
    <div className="container is-fluid">
        <Header />
      </div>
    <div className="container">
      <div className="content has-text-success">
        {!connectedAccountId && <h2></h2>}
        {connectedAccountId && !stripeConnectInstance && <h2></h2>}
        {!connectedAccountId && <p></p>}
        
        {stripeConnectInstance && (
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
              onExit={() => setOnboardingExited(true)} />

          </ConnectComponentsProvider>
        )}
        {error && <p className="error notification has-background-danger has-text-white">Quelque chose ne va pas !</p>}
        {(connectedAccountId || accountCreatePending || onboardingExited) && (
          <div className="dev-callout">
          
            {accountCreatePending && <h2 className="has-text-success has-text-centered">Veuillez patienter...</h2>}
           
          </div>
        )}
        {accountIncomplete && (
          <div className="container is-max-desktop mb-5">
          <div className="notification has-background-danger has-text-white">
            <strong>La configuration de votre compte est incomplète. Veuillez compléter toutes les informations requises.</strong>
          </div>
          </div>
        )}
        
      </div>
    </div>
    </div>
    </>
  );
}
