import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bulma/css/bulma.min.css';
import "../style/bubble.css";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { fr } from 'date-fns/locale';
import HeaderBrand from '../components/HeaderBrand';

registerLocale('fr', fr);

export default function PageReservation({ brandId }) {
  const [brand, setBrand] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedPrestation, setSelectedPrestation] = useState(null);
  const [customerFName, setCustomerFName] = useState('');
  const [customerLName, setCustomerLName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [reservationId, setReservationId] = useState('');
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const response = await axios.get(`https://drimo.alwaysdata.net/api/brands/${brandId}`);
        setBrand(response.data);
      } catch (err) {
        console.error('Erreur lors de la récupération des données:', err);
      }
    };

    fetchBrandData();
  }, [brandId]);

  const handleReservation = async () => {
    if (!selectedDate || !selectedTime || !selectedPrestation || !customerFName || !customerLName || !customerEmail || !customerPhone) {
      alert('Veuillez remplir tous les champs requis.');
      return;
    }

    const formattedPrestation = {
      name: String(selectedPrestation.name),
      price: String(selectedPrestation.price)
    };

    const dataToSubmit = {
      customerFName,
      customerLName,
      customerEmail,
      customerPhone,
      dateTime: [{
        day: selectedDate.toLocaleDateString('fr-FR', { weekday: 'long' }),
        hour: selectedTime.split(':')[0],
        minute: selectedTime.split(':')[1],
        date: selectedDate.toLocaleDateString('fr-FR'),
      }],
      prestation: [formattedPrestation],
      comment,
      reviewReservation: null,
      starReservation: null,
      status: null,
      brand_id: brandId,
      customer_id: null,
      payment_status: null,
      checkout_session: null,
      is_transferred: null,
      is_refunded: false
    };
    const reservationData = {
      customerFName,
      customerLName,
      customerEmail,
      customerPhone,
      dateTime: [{
        day: selectedDate.toLocaleDateString('fr-FR', { weekday: 'long' }),
        hour: selectedTime.split(':')[0],
        minute: selectedTime.split(':')[1],
        date: selectedDate.toLocaleDateString('fr-FR'),
      }],
      prestationName: selectedPrestation.name,
      prestationPrice: selectedPrestation.price,
      comment,
      status: true,
      brand_id: brandId,
      brandName: brand.name,
      selectedDate,
      selectedTime,
      _id: reservationId,
      checkout_session: null,
      is_transferred: null,
      is_refunded: false
    };

    setLoading(true);

    try {
      const reservationResponse = await axios.post('https://drimo.alwaysdata.net/api/reservation', dataToSubmit);
      console.log(reservationResponse.data);
      setReservationId(reservationResponse.data.insertedId);
      reservationData._id = reservationResponse.data.insertedId;
      console.log('_id: ', reservationResponse.data.insertedId);
      const stripeResponse = await axios.post('https://drimo.alwaysdata.net/nique', reservationData);
      console.log(reservationData);

      const clientSecret = stripeResponse.data.client_secret;

      if (clientSecret) {
        // Envoyer la requête de réservation
        if (reservationResponse.status === 201) {
          window.location.href = `https://www.clahess.com/checkout?client_secret=${clientSecret}`;
        } else {
          alert('Erreur lors de la réservation, veuillez vérifier votre email');
        }
      } else {
        throw new Error('client_secret non trouvé dans la réponse');
      }
    } catch (error) {
      console.error('Erreur lors de la réservation:', error);
      alert('Erreur lors de la réservation, veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  // Calcul des frais de service et du prix total
  const stripeRate = 0.057; // Taux de commission de Stripe
  const stripeFixedFeeCents = 30; // Commission fixe de Stripe en centimes (0.30€ = 30 centimes)
  const serviceFees = selectedPrestation ? ((parseFloat(selectedPrestation.price) * 100 + stripeFixedFeeCents) / (1 - stripeRate)) / 100 - parseFloat(selectedPrestation.price) : 0;
  const totalPrice = selectedPrestation ? Math.ceil((parseFloat(selectedPrestation.price) * 100 + stripeFixedFeeCents) / (1 - stripeRate)) / 100 : 0;

  // Générer les options horaires en tranches de 30 minutes basées sur les horaires d'ouverture et de fermeture
  const generateTimeOptions = () => {
    if (!selectedDate || !brand) return [];

    const dayOfWeek = selectedDate.toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();
    const day = brand.openDate.find(d => d.day.toLowerCase() === dayOfWeek);
    if (!day || day.isClosed) return [];

    const openHour = parseInt(day.open.split(':')[0], 10);
    const openMinute = parseInt(day.open.split(':')[1], 10);
    const closeHour = parseInt(day.close.split(':')[0], 10);
    const closeMinute = parseInt(day.close.split(':')[1], 10);

    const times = [];
    let hour = openHour;
    let minute = openMinute;

    while (hour < closeHour || (hour === closeHour && minute < closeMinute)) {
      times.push(`${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`);
      minute += 30;
      if (minute >= 60) {
        minute = 0;
        hour += 1;
      }
    }

    return times;
  };

  // Filtrer les dates disponibles pour le calendrier
  const filterDate = (date) => {
    const dayOfWeek = date.toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();
    const openDay = brand.openDate.find(d => d.day.toLowerCase() === dayOfWeek);
    return openDay && !openDay.isClosed;
  };

  if (!brand) {
    return <div>Chargement...</div>;
  }

  const openModal = (text) => {
    setModalText(text);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.table(brand.photo);


  const validateEmail = (email) => {
    // Expression régulière pour valider le format de l'e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    // Vérification de la validité de l'email après que l'utilisateur a fini de le saisir
    if (!validateEmail(customerEmail)) {
      setEmailError('Veuillez saisir une adresse électronique valide');
    } else {
      setEmailError(''); // Pas d'erreur si l'email est valide
    }
  };



  return (<>
    <HeaderBrand/>
    <div className="has-background-link">
      <div className="container mt-5">
        <h1 className="title has-text-success">{brand.name}</h1>
        <p className="has-text-success"><strong className="has-text-success">Type d'enseigne :</strong> {brand.type}</p>
        <p className="has-text-success"><strong className="has-text-success">Description :</strong> {brand.description}</p>
        <p className="has-text-success"><strong className="has-text-success">Téléphone :</strong> {brand.phone}</p>

        {brand.photo && brand.photo.length > 0 && (
          <div className="columns is-multiline my-2">
            {brand.photo.slice(0, 5).map((photo, index) => (
              <div className="column is-one-fifth is-flex is-justify-content-center" key={index}>
                <figure className="image is-512x512">
                  <img src={`https://drimo.alwaysdata.net/${photo}`} alt={`Image ${index + 1}`} />
                </figure>
              </div>
            ))}
          </div>
        )}

        <h3 className="subtitle mt-5 has-text-success">Sélectionnez une prestation</h3>
        <div className="columns is-multiline">
          {brand.prestations.map((prestation, index) => (
            <div className="column is-one-third" key={index}>
              <div
                className={`card ${selectedPrestation === prestation ? 'has-background-success-bold' : ''}`}
                onClick={() => setSelectedPrestation(prestation)}
                style={{ cursor: 'pointer', border: selectedPrestation === prestation ? '2px solid #3273dc' : '1px solid #ccc' }}
              >
                <div className="card-content">
                  <p className="title is-4 has-text-success-bold" >{prestation.name}</p>
                  <p className="subtitle has-text-success-bold">${parseFloat(prestation.price).toFixed(2)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {selectedPrestation && (
          <div className="container mt-4">
            <h4 className="title is-5 has-text-success">Détails de la prestation choisie</h4>
            <p className="has-text-success"><strong className="has-text-success">Prestation :</strong> {selectedPrestation.name}</p>
            <p className="has-text-success"><strong className="has-text-success">Prix de la prestation :</strong> ${parseFloat(selectedPrestation.price).toFixed(2)}</p>
            <p className="has-text-success">
              <strong className="has-text-success">Frais de service :</strong> ${parseFloat(serviceFees).toFixed(2)}
              <span className="icon ml-2 is-clickable" onClick={() => openModal(`
                  Les frais de service incluent la commission Stripe et les frais fixes.
                  `)}>
                <i className="fa-solid fa-info-circle has-text-success"></i>
              </span>
            </p>
            <p className="has-text-success"><strong className="has-text-success">Prix total :</strong> ${parseFloat(totalPrice).toFixed(2)}</p>
          </div>
        )}

        <h3 className="subtitle mt-5 has-text-success">Sélectionnez une date</h3>
        <div className="field">
          <div className="control">
            <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              className="input has-background-link has-text-success"
              placeholderText="Sélectionnez une date"
              filterDate={filterDate}
              locale="fr" // Utilisation de la locale française
              minDate={new Date()} // Empêcher la sélection des dates antérieures à la date courante
            />
          </div>
        </div>

        {selectedDate && (
          <div className="mt-4">
            <h3 className="subtitle has-text-success">Sélectionnez une heure</h3>
            <div className="field">
              <label className="label has-text-success">Choisir une heure :</label>
              <div className="control">
                <div className="select">
                  <select value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} className="has-background-link has-text-success">
                    <option value="">Sélectionnez une heure</option>
                    {generateTimeOptions().map((time, index) => (
                      <option key={index} value={time}>{time}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        <h3 className="subtitle mt-5 has-text-success">Informations du client</h3>
        <div className="field">
          <label className="label has-text-success">Prénom:</label>
          <div className="control">
            <input className="input has-background-link has-text-success" type="text" value={customerFName} onChange={(e) => setCustomerFName(e.target.value)} />
          </div>
        </div>
        <div className="field">
          <label className="label has-text-success">Nom:</label>
          <div className="control">
            <input className="input has-background-link has-text-success" type="text" value={customerLName} onChange={(e) => setCustomerLName(e.target.value)} />
          </div>
        </div>
        <div className="field">
          <label className="label has-text-success">Email:</label>
          <div className="control">
        <input
          className={`input has-background-link ${emailError ? 'is-danger' : 'has-text-success'}`}
          type="email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          onBlur={handleEmailBlur}
        />
      </div>
      {emailError && <p className="help is-danger">{emailError}</p>}
        </div>
        <div className="field">
          <label className="label has-text-success">Téléphone:</label>
          <div className="control">
            <input className="input has-background-link has-text-success" type="tel" value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)} />
          </div>
        </div>

        <div className="field mt-5">
          <label className="label has-text-success">Commentaire</label>
          <div className="control">
            <textarea className="textarea has-background-link has-text-success" value={comment} onChange={(e) => setComment(e.target.value)} />
          </div>
        </div>

        <div className="control">
          <button
            className={`button has-background-success has-text-link my-4 ${loading ? 'is-loading' : ''}`}
            onClick={handleReservation}
            disabled={loading || !selectedDate || !selectedTime || !selectedPrestation || !customerFName || !customerLName || !customerEmail || !customerPhone}
          >
            Passer une réservation
          </button>
        </div>
        {isModalOpen && (
          <div className="modal is-active">
            <div className="modal-background" onClick={closeModal}></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Informations</p>
                <button className="delete" aria-label="close" onClick={closeModal}></button>
              </header>
              <section className="modal-card-body">
                <p>{modalText}</p>
              </section>
              <footer className="modal-card-foot">
                <button className="button is-primary has-text-link" onClick={closeModal}>Fermer</button>
              </footer>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
}