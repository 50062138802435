import React from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import of jwt-decode
import { Link } from 'react-router-dom';
import 'bulma/css/bulma.min.css';

export default function HeaderBrand() {
    const token = localStorage.getItem('token');
    let decodedToken;
    let navLinks;

    if (token) {
        try {
            decodedToken = jwtDecode(token);
        } catch (error) {
            console.error('Token decoding failed', error);
            decodedToken = null;
        }
    }

    navLinks = (
        <nav className="level pt-3 pb-5" style={{ backgroundColor: '#E0F8EC' }}>
        <div className="level-item">
            <img style={{ width: '45px' }} src="/logo.png" alt="drimo" />
        </div>
    </nav>
    
    );

    return (
        <header>
            {navLinks}
        </header>
    );
}