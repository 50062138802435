import * as React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';

import HeaderCheckout from './HeaderCheckout';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_R5tPjZTcM4qtaqqHODts4vaL00rSCv85jU');

export default function Checkout() {
  const query = useQuery();
  const clientSecret = query.get('client_secret');
  const options = { clientSecret };
  return (<>
  <HeaderCheckout/>
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={options}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
    </>
  )
}