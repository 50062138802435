import React from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import of jwt-decode
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import 'bulma/css/bulma.min.css';
import NotificationBanner from '../pages/NotificationBanner';

export default function Header() {
  const token = localStorage.getItem('token');
  let decodedToken;
  let navLinks;

  if (token) {
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.error('Token decoding failed', error);
      decodedToken = null;
    }
  }

  if (!decodedToken) {
    navLinks = (
      <nav className="level pt-3 pb-5">
        <div className="level-left">
          <div className="level-item">
            <a href="/"><img style={{ width: '45px' }} src="/logo.png" alt="drimo" /></a>
          </div>
        </div>
        <div className="level-right">
          <div className="is-flex is-justify-content-end">
            <HashLink className="button is-success has-text-link level-item mx-2" smooth to="/#calendrier">Nous contacter</HashLink>
            <Link className="button is-info has-text-link level-item mx-2" to="/register">Inscription</Link>
            <Link className="button is-info has-text-link level-item ml-2" to="/login">Connexion</Link>
          </div>
        </div>
      </nav>
    );
  } else if (decodedToken.stripe.sub_type === 'Entreprise') {
    if (window.location.pathname === "/connect_register") {
      navLinks = (
        <nav className="pt-3 pb-5 level">
          <div className="level-left">
            <div className="level-item">
              <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
            </div>
          </div>
          <div className="level-right">
            <Link className="button has-background-success has-text-link is-responsive level-item" to="/logout">
              <span className="icon is-small">
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </span>
              <div>
                Déconnexion
              </div>
            </Link>
          </div>
        </nav>
      );
    } else if (window.location.pathname === "/dashboard") {
      navLinks = (
        <>
          <nav className="pt-3 pb-5 level">
            <div className="level-left">
              <div className="level-item">
                <img src="/logo.png" alt="Drimo" style={{ width: '45px' }} />
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="dropdown is-hoverable mr-2">
                  <div className="dropdown-trigger">
                    <button
                      className="button has-background-info has-text-link is-responsive"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span className="icon is-small">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      <span>Stripe</span>
                      <span className="icon is-small">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu mr-2" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-background-info has-text-link">
                      <a href="/payouts" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-regular fa-credit-card"></i>
                        </span>
                        <span>Mon argent</span>
                      </a>
                      <a href="/disputes" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-flag"></i>
                        </span>
                        <span>Réclamation</span>
                      </a>
                      <a href="/stripe-info" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-user-gear"></i>
                        </span>
                        <span>Gestion des informations Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
                <a className="button has-background-success has-text-link is-responsive" href="/logout">
                  <span className="icon is-small">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span>Déconnexion</span>
                </a>
              </div>
            </div>
          </nav>
          <NotificationBanner />
        </>
      );
    } else {
      navLinks = (
        <>
          <nav className="pt-3 pb-5 level">
            <div className="level-left">
              <div className="level-item">
                <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="dropdown is-hoverable mr-2">
                  <div className="dropdown-trigger">
                    <button
                      className="button has-background-info has-text-link is-responsive"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span className="icon is-small">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      <span>Stripe</span>
                      <span className="icon is-small">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu mr-2" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-background-info has-text-link">
                      <a href="/payouts" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-regular fa-credit-card"></i>
                        </span>
                        <span>Mon argent</span>
                      </a>
                      <a href="/disputes" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-flag"></i>
                        </span>
                        <span>Réclamation</span>
                      </a>
                      <a href="/stripe-info" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-user-gear"></i>
                        </span>
                        <span>Gestion des informations Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
                <a className="button has-background-success has-text-link is-responsive" href="/logout">
                  <span className="icon is-small">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span>Déconnexion</span>
                </a>
              </div>
            </div>
          </nav>
        </>
      );
    }
  } else if (decodedToken.stripe.sub_type === 'Premium') {
    let email = decodedToken.email;
    const manage_sub = `https://billing.stripe.com/p/login/test_6oE9CI4ae5Fn2tO7ss?prefilled_email=${email}`;
    if (window.location.pathname === "/connect_register") {
      navLinks = (
        <nav className="pt-3 pb-5 level">
          <div className="level-left">
            <div className="level-item">
              <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
            </div>
          </div>
          <div className="level-right">
            <a className="button is-warning level-item is-responsive mr-6 is-small has-text-link" href={manage_sub}>Passer à la version supérieur</a>
            <Link className="button has-background-success has-text-link is-responsive level-item" to="/logout">
              <span className="icon is-small">
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </span>
              <div>
                Déconnexion
              </div>
            </Link>
          </div>
        </nav>
      );
    } else if (window.location.pathname === "/dashboard") {
      navLinks = (
        <>
          <nav className="pt-3 pb-5 level">
            <div className="level-left">
              <div className="level-item">
                <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
              </div>
            </div>
            <div className="level-right">
              <a className="button is-warning level-item is-responsive mr-3 has-text-link" href={manage_sub}>
                <span className="icon is-small">
                  <i className="fa-solid fa-chart-line"></i>
                </span>
                <span>Passer à la version supérieur</span>
              </a>
              <div className="level-item">
                <div className="dropdown is-hoverable mr-2">
                  <div className="dropdown-trigger">
                    <button
                      className="button has-background-info has-text-link is-responsive"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span className="icon is-small">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      <span>Stripe</span>
                      <span className="icon is-small">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu mr-2" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-background-info has-text-link">
                      <a href="/payouts" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-regular fa-credit-card"></i>
                        </span>
                        <span>Mon argent</span>
                      </a>
                      <a href="/disputes" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-flag"></i>
                        </span>
                        <span>Réclamation</span>
                      </a>
                      <a href="/stripe-info" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-user-gear"></i>
                        </span>
                        <span>Gestion des informations Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
                <a className="button has-background-success has-text-link is-responsive" href="/logout">
                  <span className="icon is-small">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span>Déconnexion</span>
                </a>
              </div>
            </div>
          </nav>
          <NotificationBanner />
        </>
      );
    } else {
      navLinks = (
        <>
          <nav className="pt-3 pb-5 level">
            <div className="level-left">
              <div className="level-item">
                <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
              </div>
            </div>
            <div className="level-right">
              <a className="button is-warning level-item is-responsive mr-3 has-text-link" href={manage_sub}>
                <span className="icon is-small">
                  <i className="fa-solid fa-chart-line"></i>
                </span>
                <span>Passer à la version supérieur</span>
              </a>
              <div className="level-item">
                <div className="dropdown is-hoverable mr-2">
                  <div className="dropdown-trigger">
                    <button
                      className="button has-background-info has-text-link is-responsive"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span className="icon is-small">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      <span>Stripe</span>
                      <span className="icon is-small">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu mr-2" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-background-info has-text-link">
                      <a href="/payouts" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-regular fa-credit-card"></i>
                        </span>
                        <span>Mon argent</span>
                      </a>
                      <a href="/disputes" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-flag"></i>
                        </span>
                        <span>Réclamation</span>
                      </a>
                      <a href="/stripe-info" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-user-gear"></i>
                        </span>
                        <span>Gestion des informations Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
                <a className="button has-background-success has-text-link is-responsive" href="/logout">
                  <span className="icon is-small">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span>Déconnexion</span>
                </a>
              </div>
            </div>
          </nav>
        </>
      );
    }
  } else if (decodedToken.stripe.sub_type === 'Indépendant') {
    let email = decodedToken.email;
    const manage_sub = `https://billing.stripe.com/p/login/test_6oE9CI4ae5Fn2tO7ss?prefilled_email=${email}`;
    if (window.location.pathname === "/connect_register") {
      navLinks = (
        <nav className="pt-3 pb-5 level">
          <div className="level-left">
            <div className="level-item">
              <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
            </div>
          </div>
          <div className="level-right">
            <a className="button is-warning level-item is-responsive mr-3 has-text-link" href={manage_sub}>
              <span className="icon is-small">
                <i className="fa-solid fa-chart-line"></i>
              </span>
              <span>Passer à la version supérieur</span>
            </a>
            <Link className="button has-background-success has-text-link is-responsive level-item" to="/logout">
              <span className="icon is-small">
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </span>
              <div>
                Déconnexion
              </div>
            </Link>
          </div>
        </nav>
      );
    } else if (window.location.pathname === "/dashboard") {
      navLinks = (
        <>
          <nav className="pt-3 pb-5 level">
            <div className="level-left">
              <div className="level-left">
                <div className="level-item">
                  <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
                </div>
              </div>
            </div>
            <div className="level-right">
              <a className="button is-warning level-item is-responsive mr-3 has-text-link" href={manage_sub}>
                <span className="icon is-small">
                  <i className="fa-solid fa-chart-line"></i>
                </span>
                <span>Passer à la version supérieur</span>
              </a>
              <div className="level-item">
                <div className="dropdown is-hoverable mr-2">
                  <div className="dropdown-trigger">
                    <button
                      className="button has-background-info has-text-link is-responsive"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span className="icon is-small">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      <span>Stripe</span>
                      <span className="icon is-small">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu mr-2" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-background-info has-text-link">
                      <a href="/payouts" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-regular fa-credit-card"></i>
                        </span>
                        <span>Mon argent</span>
                      </a>
                      <a href="/disputes" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-flag"></i>
                        </span>
                        <span>Réclamation</span>
                      </a>
                      <a href="/stripe-info" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-user-gear"></i>
                        </span>
                        <span>Gestion des informations Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
                <a className="button has-background-success has-text-link is-responsive" href="/logout">
                  <span className="icon is-small">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span>Déconnexion</span>
                </a>
              </div>
            </div>
          </nav>
          <NotificationBanner />
        </>
      );
    } else {
      navLinks = (
        <>
          <nav className="pt-3 pb-5 level">
            <div className="level-left">
              <div className="level-item">
                <img src="/logo.png" alt="Drimo" className="mr-2" style={{ width: "45px" }} />
              </div>
            </div>
            <div className="level-right">
              <a className="button is-warning level-item is-responsive mr-3 has-text-link" href={manage_sub}>
                <span className="icon is-small">
                  <i className="fa-solid fa-chart-line"></i>
                </span>
                <span>Passer à la version supérieur</span>
              </a>
              <div className="level-item">
                <div className="dropdown is-hoverable mr-2">
                  <div className="dropdown-trigger">
                    <button
                      className="button has-background-info has-text-link is-responsive"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span className="icon is-small">
                        <i className="fa-solid fa-wallet"></i>
                      </span>
                      <span>Stripe</span>
                      <span className="icon is-small">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="dropdown-menu mr-2" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-background-info has-text-link">
                      <a href="/payouts" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-regular fa-credit-card"></i>
                        </span>
                        <span>Mon argent</span>
                      </a>
                      <a href="/disputes" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-flag"></i>
                        </span>
                        <span>Réclamation</span>
                      </a>
                      <a href="/stripe-info" className="dropdown-item has-background-info has-text-link">
                        <span className="icon is-small mr-1">
                          <i className="fa-solid fa-user-gear"></i>
                        </span>
                        <span>Gestion des informations Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
                <a className="button has-background-success has-text-link is-responsive" href="/logout">
                  <span className="icon is-small">
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                  <span>Déconnexion</span>
                </a>
              </div>
            </div>
          </nav>
        </>
      );
    }
  } else {
    navLinks = (
      <nav>
        <h1>Erreur lors de la récuperation de l'offre que vous avez selectionné </h1>
      </nav>
    );
  }

  return (
    <header>
      {navLinks}
    </header>
  );
}