import React, { useEffect, useState } from 'react';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Logout from './pages/Logout';
import Home from './pages/Home';
import Brands from './pages/Brands';
import Connectostripe from './pages/Connectostripe';
import CreateBrand from './pages/CreateBrand';
import UpdateBrand from './pages/UpdateBrand';
import 'bulma/css/bulma.min.css';
import "./style/index.css";
import SubscriptionElements from './components/SubscriptionElements';
import SocketManager from './components/SocketManager';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreateInventory from './pages/CreateInventory';
import Inventory from './pages/Inventory';
import UpdateInventory from './pages/UpdateInventory';
import ItemsForSale from './pages/ItemsForSale';
import CreateReservation from './pages/CreateReservation';
import Reservation from './pages/Reservation';
import UpdateReservation from './pages/UpdateReservation';
import RefuseReservation from './pages/RefuseReservation';
import ReservationHistory from './pages/ReservationHistory';
import PDFReservation from './pages/PDFReservation';
import Checkout from './components/Checkout';
import PageReservation from './pages/PageReservation';
import axios from 'axios';
import CheckoutStatus from './pages/CheckoutStatus';
import SellHistory from './pages/SellHistory';
import CancelReservation from './pages/CancelReservation';
import ProtectedRoute from './components/ProtectedRoute';
import Payouts from './pages/Payouts';
import NotificationBanner from './pages/NotificationBanner';
import Payments from './pages/Payments';
import AccountManagement from './pages/AccountManagement';

export default function App() {

  const [router, setRouter] = useState(null);

  useEffect(async () => {
    try {
      // Récupérer les IDs des brands depuis le backend
      const response = await axios.get('https://drimo.alwaysdata.net/brands');


      const brands = response.data;
      console.log(brands);
      // Créer des routes dynamiques pour chaque brandId
      const brandRoutes = brands.map((brand) => ({
        path: `/brand/${brand}`,
        element: <PageReservation brandId={brand} />, // Passez l'ID de l'enseigne à BrandPage
      }));

      // Créer le routeur avec les routes statiques et dynamiques
      const routerInstance = createBrowserRouter([
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/dashboard",
          element: <ProtectedRoute Component={Dashboard} />,
        },
        {
          path: "/sub",
          element: <SubscriptionElements />,
        },
        {
          path: "/logout",
          element: <ProtectedRoute Component={Logout} />,
        },
        {
          path: "/connect_register",
          element: <Connectostripe/>,
        },
        {
          path: "/brands",
          element: <ProtectedRoute Component={Brands} />,
        },
        {
          path: "/brands/create",
          element: <ProtectedRoute Component={CreateBrand} />,
        },
        {
          path: "/brands/update/:id",
          element: <ProtectedRoute Component={UpdateBrand} />,
        },
        {
          path: "/inventory",
          element: <ProtectedRoute Component={Inventory} />,
        },
        {
          path: "/inventory/create",
          element: <ProtectedRoute Component={CreateInventory} />,
        },
        {
          path: "/inventory/update/:id",
          element: <ProtectedRoute Component={UpdateInventory} />,
        },
        {
          path: "/items-for-sale",
          element: <ProtectedRoute Component={ItemsForSale} />,
        },
        {
          path: "/reservation",
          element: <ProtectedRoute Component={Reservation} />,
        },
        {
          path: "/reservation/create",
          element: <ProtectedRoute Component={CreateReservation} />,
        },
        {
          path: "/reservation/update/:id",
          element: <ProtectedRoute Component={UpdateReservation} />,
        },
        {
          path: "/reservation/refuse/:id",
          element: <ProtectedRoute Component={RefuseReservation} />,
        },
        {
          path: "/reservation-history",
          element: <ProtectedRoute Component={ReservationHistory} />,
        },
        {
          path: "/reservation-history/pdf",
          element: <ProtectedRoute Component={PDFReservation} />,
        },
        {
          path: "/checkout",
          element: <Checkout />,
        },
        {
          path: "/reservation_status",
          element: <CheckoutStatus />,
        },
        {
          path: "/cancel_reservation/:id",
          element: <CancelReservation />,
        },
        {
          path: "/sales-history",
          element: <ProtectedRoute Component={SellHistory} />
        },
        {
          path: "/payouts",
          element: <ProtectedRoute Component={Payouts} />
        },
        {
          path: "/disputes",
          element: <Payments/>
        },
        {
          path: "/stripe-info",
          element: <AccountManagement/>
        },
        ...brandRoutes, // Ajoutez les routes dynamiques ici
      ]);

      setRouter(routerInstance);

    } catch (error) {
      console.error("Erreur lors de la récupération des brands:", error);
    };
  }, []);

  if (!router) {
    return <div>Chargement des routes...</div>; // Ajoutez un état de chargement
  }

  return (<>

    <SocketManager />
    <RouterProvider router={router} />
  </>
  );
}